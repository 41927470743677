import React, { useEffect, useState } from "react"
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams();

	const [data, setData] = useState({})

	async function getData() {
		const response = await GET(`/inventory/${id}`, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('inventory')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('inventory')} {formatDate(data.created_at)}</h4>
					<div className="d-flex flex-wrap">
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('pos')}:</div>
								<div className="w-100 text-end text-nowrap">{data.pos_name}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('warehouse')}:</div>
								<div className="w-100 text-end text-nowrap">{data.wms_name}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('note')}:</div>
								<div className="w-100 text-end text-nowrap">{data.note}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('unit')}.</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('expected_balance')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('counted')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('divergence')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('cost_difference')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.elements?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-center">{item.unit_name}</td>
										<td className="text-end">{formatMoney(item.price)}</td>
										<td className="text-end">{formatMoney(item.balance, 2)}</td>
										<td className="text-end">{formatMoney(item.actual_balance, 2)}</td>
										<td className="text-end">{formatMoney(item.divergence, 2)}</td>
										<td className="text-end">{formatMoney(item.difference_amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-end">
						<div className="flex-column w-25">
							<h5 className="d-flex justify-content-between">
								<strong>{t('surplus')}:</strong>
								<span>{formatMoney(data.surplus)}</span>
							</h5>
							<h5 className="d-flex gap-2 justify-content-between">
								<strong>{t('lack_of')}:</strong>
								<span>{formatMoney(data.shortage)}</span>
							</h5>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-3">
						<Link to="/inventory-wms" className="btn btn-warning btn-rounded btn-wide">
							{t('cancel')}
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default Preview
