import axios from 'axios'
import { showLoader, hideLoader } from '../store/loader/actions';
import { store } from '../store/index'
import { toast } from 'react-toastify'
import { clearTemporaryStorage } from 'helpers/helpers'
import i18n from '../i18n'

var baseURL = '';
if (process.env.NODE_ENV === 'production') {
	baseURL = 'https://backend.mison.uz/api'
} else {
	baseURL = 'http://127.0.0.1:8000/api'
	// baseURL = 'https://backend.mison.uz/api'
}

//axios.defaults.timeout = 1000;
// console.log(error.response.data);
// console.log(error.response.status);
// console.log(error.response.headers);

const axiosClient = axios.create({
	baseURL: baseURL,
	//timeout: 2000,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		"Language": localStorage.getItem("I18N_LANGUAGE")
	},
});

const axiosFile = axios.create({
	baseURL: baseURL,
	// timeout: 2000,
	withCredentials: true,
	headers: {
		'Authorization': `Bearer ${localStorage.getItem('token')}`,
		'Content-Type': 'multipart/form-data',
		'Accept': 'application/json',
		"withCredentials": true,
		"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		"Language": localStorage.getItem("I18N_LANGUAGE")
	},
});

const axiosFileDownload = axios.create({
	baseURL: baseURL,
	withCredentials: true,
	headers: {
		'Authorization': `Bearer ${localStorage.getItem('token')}`,
		"withCredentials": true,
		"Accept-Language": localStorage.getItem("I18N_LANGUAGE"),
		"Language": localStorage.getItem("I18N_LANGUAGE")
	},
});

export async function GET(URL, payload = {}, settings = {}) {
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload);
	}

	if (settings.loader) store.dispatch(showLoader());

	const data = await axiosClient.get(
		URL + params,
		{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
	).catch(error => {
		if (settings.loader) store.dispatch(hideLoader());
		httpStatusChecker(error)
		return error.response
	})
	store.dispatch(hideLoader());

	return data
}

export async function POST(URL, payload, settings = { notification: true }) {
	let jsonData = JSON.stringify(payload);

	if (settings.loader) store.dispatch(showLoader());

	const data = await axiosClient.post(URL, jsonData,
		{ headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
		.catch(error => {
			if (settings.loader) store.dispatch(hideLoader());
			httpStatusChecker(error)
			return error.response
		})

	if (settings.loader) store.dispatch(hideLoader());

	if ((data && data.status === 200) && settings?.notification) {
		toast.dismiss();
		toast.success(i18n.t('success'))
	}

	return data
}

export async function PUT(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);

	if (settings.loader) store.dispatch(showLoader());
	const data = await axiosClient.put(URL, jsonData,
		{ headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } })
		.catch(error => {
			if (settings.loader) store.dispatch(hideLoader());
			httpStatusChecker(error)
			return error.response
		});
	if (settings.loader) store.dispatch(hideLoader());
	if (data && data.status === 200) {
		toast.dismiss();
		toast.success(i18n.t('success'))
	}
	return data
}

export async function FILE(URL, payload, settings = {}) {
	if (settings.loader) store.dispatch(showLoader());
	const data = await axiosFile.post(
		URL,
		payload,
		{ headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } })
		.catch(error => {
			store.dispatch(hideLoader());
			httpStatusChecker(error)
			return error.response
		});
	store.dispatch(hideLoader());
	return data
}

export async function FILE_D(URL, settings = {}) {
	if (settings.loader) store.dispatch(showLoader());
	const data = await axiosFileDownload.get(
		URL,
		{
			responseType: 'blob',
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		})
		.catch(error => {
			store.dispatch(hideLoader());
			toast.error('Ошибка' + error)
			return error.response
		});
	store.dispatch(hideLoader());
	return data
}

export async function DELETE(URL, payload, settings = {}) {
	var params = "";
	if (Object.entries(payload).length > 0) {
		params = getPath(payload, URL);
	}

	if (settings.loader) store.dispatch(showLoader());
	const data = await axiosClient.delete(`${URL + params}`, { headers: { Authorization: localStorage.getItem('token') === null ? '' : 'Bearer ' + localStorage.getItem('token') } })
		.catch(error => {
			httpStatusChecker(error)
			return error.response
		});
	store.dispatch(hideLoader());
	if (data && data.status === 200) {
		toast.dismiss();
		toast.success(i18n.t('deleted'))
	}
	return data

}

export async function GUESTGET(URL, payload, settings = {}) {
	if (settings.loader) store.dispatch(showLoader());
	const data = await axiosClient.get(`${URL}`)
		.catch(error => {
			httpStatusCheckerGuess(error)
			return error.response
		});;
	store.dispatch(hideLoader());
	return data
}

export async function GUESTPOST(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) store.dispatch(showLoader());
	var data
	data = await axiosClient.post(`${URL}`, jsonData)
		.catch(error => {
			httpStatusCheckerGuess(error)
			return error.response
		});
	store.dispatch(hideLoader());
	return data
}

export async function GUESTPUT(URL, payload, settings = {}) {
	let jsonData = JSON.stringify(payload);
	if (settings.loader) store.dispatch(showLoader());
	var data
	data = await axiosClient.put(`${URL}`, jsonData)
		.catch(error => {
			httpStatusCheckerGuess(error)
			return error.response
		});
	store.dispatch(hideLoader());
	return data
}

function getPath(payload, url) {
	let iterations = Object.entries(payload).length;
	var pathArr = "?";
	if (url)
		url.includes("?") ? pathArr = '&' : pathArr = '?'

	for (let key in payload) {
		if (!--iterations) {
			pathArr += key + "=" + payload[key];
		} else {
			pathArr += key + "=" + payload[key] + "&";
		}
	}
	return pathArr;
}

function httpStatusChecker(error) {
	toast.dismiss();

	if (error?.response?.status === 400) {
		toast.error(error.response.data.message)
		return;
	}
	if (error?.response?.status === 403) {
		toast.error(error.response.data.message)
		return;
	}
	if (error?.response?.status === 401) {
		clearTemporaryStorage()
		if (process.env.NODE_ENV === 'production') {
			window.location.href = "https://cabinet.mison.uz/auth/login";
		} else {
			window.location.href = "http://localhost:3007/auth/login";
		}

		toast.error(i18n.t('login_or_password_invalid'))
		return;
	}
	if (error?.response?.status === 404) {
		toast.error(i18n.t('not_found'))
		return;
	}
	if (error?.response?.status === 415) {
		toast.error(i18n.t('error'))
		return;
	}
	if (error?.response?.status === 500) {
		var message = ""
		if (error.response.data.file) {
			message += error.response.data.file
		}
		if (error.response.data.line) {
			message += error.response.data.line
		}
		if (error.response.data.message) {
			message += error.response.data.message
		}
		toast.error(message)
		return;
	}
	if (!error.status) { // если нету интернета то выходит эта ошибка
		return
	}
}

function httpStatusCheckerGuess(error) {
	if (error?.response?.status === 400) {
		toast.error(error.response.data.message)
	}
	if (error?.response?.status === 401) {
		toast.error(i18n.t('login_or_password_invalid'))
	}
}

export function httpOk(response) {
	if (response?.status < 400) {
		return true
	} else {
		return false
	}
}