import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import DeleteModal from 'pages/parts/DeleteModal'
import useDidMountEffect from 'pages/parts/useDidMountEffect'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, DELETE, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign, findKeyFromArrayByValue, arrayJoin } from "helpers/helpers"
import { SET_FILTER_DATA } from "store/filterData/actions"
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)
	const reduxFilterData = useSelector(state => state.filterData)

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -1, 'name': t('deleted') },
	]

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [suppliers, setSuppliers] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		status: 1,
		ids: [],
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '',
		response: null
	})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [modalTemplate, setModalTemplate] = useState({ 'bool': false, 'id': '', 'name': '' })

	async function searchWithFilter(settings = { reduxData: false }) {
		var filterUrl = "/documents-in"
		var urlParams = ""
		checkFilter()

		var filterDataCopy = {}
		if (settings?.reduxData) {
			filterDataCopy = reduxFilterData
			urlParams = ""
		} else {
			filterDataCopy = { ...filterData }
			urlParams = ""
		}

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.supplier_id)
			urlParams += returnSign(urlParams) + 'supplier_id=' + filterDataCopy.supplier_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		if (`/documents-in${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
			filterDataCopy.url = '/documents-in'
			dispatch(SET_FILTER_DATA(filterDataCopy))
		}

		setDropdownIsOpen(false)
	}

	async function exportExcel() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/documents-in-excel"
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.supplier_id)
			urlParams += returnSign(urlParams) + 'supplier_id=' + filterDataCopy.supplier_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy?.ids?.length)
			urlParams += returnSign(urlParams) + 'ids=' + arrayJoin(filterDataCopy.ids, false)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			var excelHeaders = [[t('pos'), t('date'), t('supplier'), t('name2'), t('price'), t('quantity'), t('unit')]]
			for (let i = 0; i < response.data.length; i++) {
				var item = response.data[i]
				var excelItems = []
				excelItems.push(item['pos_name'])
				excelItems.push(formatDate(item['created_at']))
				excelItems.push(item['supplier_name'])
				excelItems.push(item['element_name'])
				excelItems.push(Number(item['price']).toFixed(2))
				excelItems.push(Number(item['quantity']).toFixed(2))
				excelItems.push(item['unit_name'])
				excelHeaders.push(excelItems)
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('good_reception') + ".xlsx");
		}
	}

	async function deleteItem() {
		const response = await DELETE(`/documents-in/${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	function toggleModalTemplate(bool, id = '') {
		setModalTemplate({ ...modalTemplate, 'bool': bool, 'id': id, 'name': '' })
	}

	async function storeTemplate() {
		await POST(
			`/documents-in-templates`,
			{
				id: modalTemplate.id,
				name: modalTemplate.name
			},
			{ loader: true }
		)

		toggleModalTemplate(false)
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < data.data.length; i++) {
				filterDataCopy.ids.push(data.data[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: filterData.pos_id ?? reduxAccount.pos_id,
			ids: [],
			search: '',
		})
		dispatch(SET_FILTER_DATA({}))
		searchWithFilter()
	}

	function checkFilter() {
		if (filterData.search === '') {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
	}

	async function getSuppliers() {
		const response = await GET('/helper/suppliers', {}, { loader: true })
		if (httpOk(response)) setSuppliers(response.data)
	}

	useEffect(() => {
		getPoses()
		getSuppliers()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('good_reception')} [{data.total}]
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/documents-in/create">
						{t('create')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('suppliers')}</label>
												<Select
													options={suppliers}
													value={suppliers.find(option => option.id === filterData.supplier_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'supplier_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>{
															t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide-2"
									onClick={() => exportExcel()}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4><b>{t('amount')}:</b> {formatMoney(data?.total_amount)}</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.supplier_id &&
									<span className="me-2">
										<b>{t('supplier')}: </b>
										{findKeyFromArrayByValue(suppliers, 'id', filterData.supplier_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '25px' }}
										className="text-center">
										<input type="checkbox"
											onChange={(e) => handleCheckboxChangeAll(e)} />
									</th>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }} className="td-ellipsis">{t('pos')}</th>
									<th style={{ 'width': '15%' }} className="td-ellipsis">{t('supplier')}</th>
									<th style={{ 'width': '5%' }} className="text-center">{t('document')}</th>
									<th style={{ 'width': '45%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-nowrap text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-nowrap text-end">{t('amount')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>
											<input type="checkbox"
												checked={filterData?.ids?.includes(item.id)}
												onChange={(e) => handleCheckboxChange(e, item.id)} />
										</td>
										<td className="text-center td-ellipsis">{item.row_number}</td>
										<td className="td-ellipsis">{item.pos_name}</td>
										<td className="td-ellipsis">{item.supplier_name}</td>
										<td className="text-center">{item.document_number}</td>
										<td className="td-ellipsis">{item.note}</td>
										<td className="text-center">
											{formatDate(item.created_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className="text-end text-nowrap">{formatMoney(item.total_amount)}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Link to={`/documents-in/preview/${item.id}`} className="table-dropdown-item">
															<i className="uil-eye"></i>
															{t('preview')}
														</Link>
														<div className="table-dropdown-item"
															onClick={() => toggleModalTemplate(true, item.id)}>
															<i className="uil-bookmark-full"></i>
															{t('templates')}
														</div>
														{item.deleatable &&
															<div className="table-dropdown-item text-danger"
																onClick={() => {
																	setDeleteModalParams({ id: item.id, index: index });
																	setModalDelete(true)
																}}>
																<i className="uil uil-times"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL TEMPLATE */}
			<Modal show={modalTemplate.bool} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalTemplate(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('save')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label>{t('template_name')}</label>
						<div className="position-relative">
							<input type="text" className="form-control" autoFocus
								value={modalTemplate.name}
								onChange={(e) => setModalTemplate({ ...modalTemplate, 'name': e.target.value })} />
						</div>
					</div>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => toggleModalTemplate(false)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary w-100"
							onClick={() => storeTemplate()}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TEMPLATE */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index