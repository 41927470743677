import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign } from "helpers/helpers"

function Index({ setActiveModal, filterData }) {
	const { t } = useTranslation()

	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/report-summary-cash"
		var urlParams = ""

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal
				show={true}
				animation={false}
				centered
				size="md"
				onHide={() => setActiveModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('cash')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '40%' }}>{t('type')}</th>
									<th className="text-end" style={{ 'width': '60%' }}>{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td className="text-success text-end">{formatMoney(item?.amount)}</td>
									</tr>
								))}
								<tr>
									<td><b>{t('total')}</b></td>
									<td className="text-success text-end"><b>{formatMoney(data.total_amount)}</b></td>
								</tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Index