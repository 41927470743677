import React, { useEffect, useState, useRef, Fragment } from "react"
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import { httpOk, GET, POST, PUT } from 'helpers/api'
import { formatMoney, formatMoneyInput } from 'helpers/helpers'

function Main() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		pos_id: reduxAccount.pos_id,
		wms_id: reduxAccount.wms_id,
		elements: [],
		products: [],
	})
	const [activeTab, setActiveTab] = useState(1)
	const [wms, setWms] = useState([])
	const [products, setProducts] = useState([])
	const [productsDuplicate, setProductsDuplicate] = useState([])
	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [addQuantity, setAddQuantity] = useState(1)
	const [modalStep, setModalStep] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)

	async function inventoryCompleted(completed = false) {
		var sendData = { ...data }

		for (let i = 0; i < sendData.elements.length; i++) {
			sendData.elements[i]['difference_amount'] =
				(sendData.elements[i]['actual_balance'] - sendData.elements[i]['balance']) * sendData.elements[i]['price']
			sendData.elements[i]['divergence'] = sendData.elements[i]['actual_balance'] - sendData.elements[i]['balance']
		}

		if (sendData.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		var response;
		if (id) {
			if (completed) {
				response = await PUT('/inventory-completed', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			} else {
				response = await PUT('/inventory', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			}
		} else {
			if (completed) {
				response = await PUT('/inventory-completed', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			} else {
				response = await POST('/inventory', sendData, { loader: true })
				if (httpOk(response)) navigate('/inventory-wms')
			}
		}
	}

	// STEP 1
	// REACTSELECT LOGIC
	function handleSelectSearchProduct(value) {
		if (!value) {
			setProducts([...productsDuplicate])
			return
		}

		var productsDuplicateCopy = [...productsDuplicate]
		var filteredItems = productsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setProducts(filteredItems)
	}

	function handleSelectProduct(option, index) {
		var dataCopy = { ...data }

		let exist = dataCopy.products.findIndex(item => item.product_id === option.product_id);
		if (exist !== -1) {
			toast.error(`Дубликат: строка ${exist + 1}`)
			return
		} else {
			dataCopy.products[index] = option
		}

		setData(dataCopy)
	}

	function closeReactSelectProduct() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true

			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDownProduct(event) {
		event.stopPropagation();
		var currentIndex = products.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var productsCopy = [...products]
			productsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						productsCopy[products.length - 1].selected = true
						currentIndex = products.length - 1
					} else {
						productsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === products.length - 1) {
						productsCopy[0].selected = true
						currentIndex = 0
					} else {
						productsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setProducts(productsCopy)
		}
	}
	// REACTSELECT LOGIC

	function addToList(length = 1, type = 'product') {
		var dataCopy = JSON.parse(JSON.stringify(data))

		if (type === 'product') {
			for (let i = 0; i < length; i++) {
				dataCopy.products.push({})
			}
		}

		if (type === 'element') {
			for (let i = 0; i < length; i++) {
				dataCopy.elements.push({})
			}
		}

		setData(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(1)
			byId?.select()
		}, 200);
	}

	function deleteProduct(index) {
		var dataCopy = { ...data }
		dataCopy.products.splice(index, 1)
		setData(dataCopy)
	}

	function deleteProducts() {
		var dataCopy = { ...data }
		dataCopy.products = []
		setData(dataCopy)
	}

	function handleProductChange(e, index) {
		var dataCopy = { ...data }
		if (Number(e.target.value) > 999999999) {
			toast.error(t('exceeds_maximum'))
			dataCopy.products[index][e.target.name] = ""
			setData(dataCopy)
			return;
		}

		let value = e.target.value;
		value = value.replace(/,/g, '.')
		dataCopy.products[index][e.target.name] = value.replace(/[^\d.]/g, '')
		setData(dataCopy)
	}

	function toggleModalStep(bool) {
		setModalStep(bool)
	}

	function fillProducts(in_product) {
		var dataCopy = { ...data }

		if (in_product === 1) {
			for (let i = 0; i < productsDuplicate.length; i++) {
				if (productsDuplicate[i]['in_product'] === 1) {
					if (!dataCopy.products.some(item => item.product_id === productsDuplicate[i]['product_id'])) {
						dataCopy.products.push(productsDuplicate[i])
					}
				}
			}
		}

		if (in_product === 2) {
			for (let i = 0; i < productsDuplicate.length; i++) {
				if (productsDuplicate[i]['in_product'] === 2) {
					if (!dataCopy.products.some(item => item.product_id === productsDuplicate[i]['product_id'])) {
						dataCopy.products.push(productsDuplicate[i])
					}
				}
			}
		}

		setData(dataCopy)
	}

	function handleChangeWMS(option) {
		setData({ ...data, 'wms_id': option.id, 'wms_name': option.name })
		getProducts()
		getElements(option.id)
	}

	function confirmStep() {
		var dataCopy = { ...data }

		dataCopy.error = false
		dataCopy.products = dataCopy.products.filter(item => item.product_id !== null && item.product_id !== '');
		for (let i = 0; i < dataCopy.products.length; i++) {
			if (dataCopy.products[i]['actual_balance'] < 0 || dataCopy.products[i]['actual_balance'] === "") {
				toast.error(t('check_filled_inputs'))
				return
			}
		}

		const aggregateIngredients = dataCopy.products.reduce((acc, product) => {
			product.ingredients.forEach(ingredient => {
				if (acc[ingredient.element_id]) {
					// Если ингредиент уже есть в аккумуляторе, суммируем количество
					acc[ingredient.element_id]['actual_balance'] = parseFloat(
						(
							Number(acc[ingredient.element_id]['actual_balance']) +
							Number(product.actual_balance) * Number(ingredient.quantity)
						).toFixed(4)
					);
				} else {
					// Если ингредиент новый, добавляем его в аккумулятор
					ingredient.actual_balance = parseFloat((Number(product.actual_balance) * Number(ingredient.quantity)).toFixed(4));
					acc[ingredient.element_id] = { ...ingredient };
				}
			});
			return acc;
		}, {});

		// Преобразование аккумулятора в массив
		const ingredientArray = Object.values(aggregateIngredients);
		dataCopy.elements = ingredientArray

		for (let i = 0; i < dataCopy.elements.length; i++) {
			for (let j = 0; j < elementsDuplicate.length; j++) {
				if (dataCopy.elements[i]['element_id'] === elementsDuplicate[j]['element_id']) {
					dataCopy.elements[i]['balance_id'] = elementsDuplicate[j]['balance_id']
					dataCopy.elements[i]['name'] = elementsDuplicate[j]['name']
					dataCopy.elements[i]['unit_name'] = elementsDuplicate[j]['unit_name']
					dataCopy.elements[i]['price'] = elementsDuplicate[j]['price']
					dataCopy.elements[i]['balance'] = elementsDuplicate[j]['balance']
					dataCopy.elements[i]['divergence'] = ""
					dataCopy.elements[i]['difference_amount'] = ""
				}
			}
		}

		toggleModalStep(false)
		setActiveTab(2)
		calculate(dataCopy)
	}
	// STEP 1

	// STEP 2
	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }

		let exist = dataCopy.elements.findIndex(item => item.element_id === option.element_id);
		if (exist !== -1) {
			toast.error(`Дубликат: строка ${exist + 1}`)
			return
		} else {
			dataCopy.elements[index]['balance_id'] = option.balance_id
			dataCopy.elements[index]['element_id'] = option.element_id
			dataCopy.elements[index]['name'] = option.name
			dataCopy.elements[index]['product_type_name'] = option.product_type_name
			dataCopy.elements[index]['unit_name'] = option.unit_name
			dataCopy.elements[index]['price'] = option.price
			dataCopy.elements[index]['balance'] = option.balance
			dataCopy.elements[index]['actual_balance'] = ''
			dataCopy.elements[index]['divergence'] = ''
			dataCopy.elements[index]['difference_amount'] = ''
		}

		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	function handleElementChange(e, index) {
		var dataCopy = { ...data }
		if (Number(e.target.value) > 999999999) {
			toast.error(t('exceeds_maximum'))
			dataCopy.elements[index][e.target.name] = ""
			setData(dataCopy)
			return;
		}

		let value = e.target.value;
		value = value.replace(/,/g, '.')
		dataCopy.elements[index][e.target.name] = value.replace(/[^\d.]/g, '')
		calculate(dataCopy)
	}

	function handleGroupElementChange(e, index, index2) {
		var dataCopy = { ...data }

		let value = e.target.value;
		value = value.replace(/,/g, '.')
		dataCopy.elements[index]['groups'][index2][e.target.name] = value.replace(/[^\d.]/g, '')

		calculate(dataCopy)
	}

	function addGroupToList(item, index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		var element = {
			"element_id": item.element_id,
			"actual_balance": ""
		}

		if (dataCopy.elements[index].groups?.length > 0) {
			dataCopy.elements[index]['groups'].push(element)
		} else {
			dataCopy.elements[index]['groups'] = [{
				...element,
				'actual_balance': item.actual_balance,
				'product_id': item.product_id,
			}]
			dataCopy.elements[index]['groups'].push(element)
		}

		setData(dataCopy)
	}

	function deleteGroupProduct(index, index2) {
		var dataCopy = { ...data }

		dataCopy.elements[index]['groups'].splice(index2, 1)
		if (dataCopy.elements[index]['groups'].length === 1) {
			delete dataCopy.elements[index]['groups'];
		}

		setData(dataCopy)
	}

	function deleteElement(index) {
		var dataCopy = { ...data }
		dataCopy.elements.splice(index, 1)
		setData(dataCopy)
	}

	function calculateGroup(item) {
		var fact = 0

		for (let i = 0; i < item.groups.length; i++) {
			fact += Number(item.groups[i]['actual_balance'])
		}

		return formatMoney(fact, 2)
	}

	function calculate(dataCopy) {
		dataCopy.balance = 0
		dataCopy.difference_quantity = 0
		dataCopy.difference_amount = 0
		console.log(dataCopy);
		
		for (let i = 0; i < dataCopy.elements.length; i++) {
			if (dataCopy.elements[i]['groups'] && dataCopy.elements[i]['groups'].length > 1) {
				var temporaryActualBalance = 0;
				for (let j = 0; j < dataCopy.elements[i]['groups'].length; j++) {
					temporaryActualBalance += Number(dataCopy.elements[i]['groups'][j]['actual_balance']);
				}

				dataCopy.elements[i]['divergence'] =
					temporaryActualBalance - dataCopy.elements[i]['balance']
				dataCopy.elements[i]['difference_amount'] =
					(temporaryActualBalance - dataCopy.elements[i]['balance']) * dataCopy.elements[i]['price']
			} else {
				dataCopy.elements[i]['divergence'] =
					dataCopy.elements[i]['actual_balance'] - dataCopy.elements[i]['balance']
				dataCopy.elements[i]['difference_amount'] =
					(dataCopy.elements[i]['actual_balance'] - dataCopy.elements[i]['balance']) * dataCopy.elements[i]['price']
			}
		}

		setData(dataCopy)
	}
	// STEP 2

	async function getWms() {
		const response = await GET(`/helper/wms?pos_id=${reduxAccount.pos_id}`)
		if (httpOk(response)) setWms(response.data)
	}

	async function getProducts() {
		const response = await GET(`/inventory-products`)
		if (httpOk(response)) {
			setProducts(response.data)
			setProductsDuplicate(response.data)
		}
	}

	async function getElements(wms_id = reduxAccount.wms_id) {
		var sendData = {
			"pos_id": reduxAccount.pos_id,
			"wms_id": wms_id,
		}
		const response = await GET(`/inventory-elements`, sendData)
		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}
	}

	useEffect(() => {
		getWms()
		getProducts()
		getElements()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{activeTab === 1 &&
				<>
					<div className="page-header d-flex justify-content-between">
						<div className="d-flex text-truncate">
							<h4 className="vertical-center">
								Шаг 1: Приготовленные блюда загатовки
							</h4>
						</div>
						<DropdownButton title={t('action')}>
							<Dropdown.Item onClick={() => fillProducts(1)}>
								Заполнить продуктами
							</Dropdown.Item>
							<Dropdown.Item onClick={() => fillProducts(2)}>
								Заполнить загатовками
							</Dropdown.Item>
							<Dropdown.Item onClick={() => deleteProducts()}>
								Очистить список
							</Dropdown.Item>
						</DropdownButton>
					</div>

					<div className="card mb-3">
						<div className="card-body">
							<div className="row">
								<div className="col-md-2">
									<div className="form-groups">
										<label>{t('warehouse')}<span className="required-mark">*</span></label>
										<Select
											isDisabled={data.elements.length > 0}
											value={wms.find(option => option.id === data.wms_id)}
											options={wms}
											onChange={(option) => handleChangeWMS(option)}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								</div>
								<div className="col-md-2">
									<div className="form-groups">
										<label>{t('note')}</label>
										<input name="note" className="form-control"
											autoComplete="off"
											value={data.note ?? ''}
											onChange={(e) => setData({ ...data, 'note': e.target.value })} />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
								<thead>
									<tr>
										<th style={{ 'minWidth': '40px' }} className="text-center">#</th>
										<th style={{ 'width': '30%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }}>{t('type')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('counted')}</th>
										<th style={{ 'width': '10%' }}>{t('unit')}</th>
										<th style={{ 'width': '40%' }}></th>
										<th style={{ 'minWidth': '35px' }} className="text-end"></th>
									</tr>
								</thead>
								<tbody>
									{data?.products?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td className="td-ellipsis">
												<ReactSelect
													search={handleSelectSearchProduct}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={products}
													onClose={() => closeReactSelectProduct()}
													selectItem={(option) => handleSelectProduct(option, index)}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDownProduct(e)
														}
													}}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{products.map((selectItem, selectIndex) => (
																	<tr
																		id={selectItem.product_id}
																		className={`cursor ${((selectItem.selected || (selectItem.product_id === item.product_id)) ? 'selected' : '')}`}
																		tabIndex={-1}
																		key={selectIndex}
																		onClick={() => handleSelectProduct(selectItem, index)}
																		onKeyDown={(e) => handleTableKeyDownProduct(e, selectItem, index)}>
																		<td>{selectItem.name}</td>
																		<td className="text-end text-nowrap">{selectItem.product_type_name}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											</td>
											<td>{item.product_type_name}</td>
											<td className="td-input" style={!item.actual_balance ? { 'borderBottom': '2px solid red' } : {}}>
												<input
													type="text"
													name="actual_balance"
													autoComplete="off"
													className={`form-control text-end ${item.actual_balance < 0 ? 'required-border' : ''}`}
													placeholder="0"
													value={item.actual_balance ?? ''}
													onChange={(e) => handleProductChange(e, index)}
													onKeyUp={(event) => {
														if (event.key === 'Enter') {
															addToList(1, 'product')
														}
													}} />
											</td>
											<td>{item.unit_name}</td>
											<td></td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-center"
													onClick={() => deleteProduct(index)}>
													<div className="table-action-button table-action-danger-button">
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												tabIndex={-1}
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^\d]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={!data.pos_id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity, 'product')
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>

							<div className="btn-wrapper mt-5">
								<button type="button" className="btn btn-primary btn-rounded btn-wide"
									disabled={!(data.pos_id || data.wms_id)}
									onClick={() => toggleModalStep(true)}>
									{t('continue')}
								</button>
							</div>
						</div>
					</div>
				</>
			}

			{activeTab === 2 &&
				<>
					<div className="page-header d-flex justify-content-between">
						<div className="d-flex text-truncate">
							<h4 className="vertical-center">
								Шаг 2: Подсчет элементов
							</h4>
						</div>
						{/* <DropdownButton title={t('action')}>
							<Dropdown.Item onClick={() => fillProducts(1)}>
								Заполнить элементами
							</Dropdown.Item>
						</DropdownButton> */}
					</div>

					<div className="card mb-3">
						<div className="card-body">
							<h4 className="fw-600">{t('inventory')}</h4>
							<div className="d-flex flex-wrap">
								<div className="me-3">
									<div className="d-flex d-none">
										<div className="fw-600 text-nowrap me-3">{t('pos')}:</div>
										<div className="w-100 text-end text-nowrap">{data.pos_name}</div>
									</div>
									<div className="d-flex d-none">
										<div className="fw-600 text-nowrap me-3">{t('warehouse')}:</div>
										<div className="w-100 text-end text-nowrap">{data.wms_name}</div>
									</div>
									<div className="d-flex">
										<div className="fw-600 text-nowrap me-3">{t('note')}:</div>
										<div className="w-100 text-end text-nowrap">{data.note}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
								<thead>
									<tr>
										<th style={{ 'minWidth': '40px' }} className="text-center">#</th>
										<th style={{ 'width': '30%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('counted')}</th>
										<th style={{ 'width': '10%' }}>{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('fact')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('difference')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
										<th style={{ 'minWidth': '35px' }} className="text-end"></th>
										<th style={{ 'minWidth': '35px' }} className="text-end"></th>
									</tr>
								</thead>
								<tbody>
									{data.elements.map((item, index) => (
										item.groups?.length > 0 ?
											<Fragment key={index}>
												<tr className="border-none">
													<td className="vertical-middle text-center" rowSpan={item.groups.length}>
														{index + 1}
													</td>
													<td className="vertical-middle border-bottom">
														<div>
															<ReactSelect
																search={handleSelectSearch}
																value={item.name}
																index={index}
																scrollRef={scrollRef}
																options={elements}
																onClose={() => closeReactSelect()}
																selectItem={(option) => handleSelectElement(option, index)}
																onKeyUp={(e) => {
																	if (e.keyCode === 40 || e.keyCode === 38) {
																		handleTableKeyDown(e)
																	}
																}}
																table={
																	<table className="table mb-0">
																		<tbody ref={tbodyRef}>
																			{elements.map((selectItem, selectIndex) => (
																				<tr
																					id={selectItem.id}
																					className={`cursor ${((selectItem.selected || (selectItem.id === item.element_id)) ? 'selected' : '')}`}
																					tabIndex={-1}
																					key={selectIndex}
																					onClick={() => handleSelectElement(selectItem, index)}
																					onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																					<td>{selectItem.name}</td>
																					<td className="text-end text-nowrap">{formatMoney(selectItem.price)}</td>
																				</tr>
																			))}
																		</tbody>
																	</table>
																}
															/>
														</div>
													</td>
													<td className="td-input" style={!item.groups[0]['actual_balance'] ? { 'borderBottom': '2px solid red' } : {}}>
														<input
															disabled={item.product_id}
															type="text"
															name="actual_balance"
															className={`form-control text-end ${item.groups[0]['actual_balance'] < 0 ? 'required-border' : ''}`}
															placeholder="0"
															value={formatMoneyInput(item.groups[0]['actual_balance']) ?? ''}
															onChange={(e) => handleGroupElementChange(e, index, 0)}
														/>
													</td>
													<td className="vertical-middle">{item.unit_name}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>{formatMoney(item.price)}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>{calculateGroup(item)}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>{formatMoney(item.balance, 2)}</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>
														<span className={`${item.divergence < 0 ? 'text-danger' : ''}`}>
															{formatMoney(item.divergence, 2)}
														</span>
													</td>
													<td className="vertical-middle text-end text-nowrap" rowSpan={item.groups.length}>
														<span className={`${item.difference_amount < 0 ? 'text-danger' : ''}`}>
															{formatMoney(item.difference_amount)}
														</span>
													</td>
													<td className="vertical-middle" rowSpan={item.groups.length}>
														<div className="d-flex justify-content-end">
															<div className="table-action-button table-action-primary-button"
																onClick={() => addGroupToList(item, index)}>
																<i className="uil uil-plus"></i>
															</div>
														</div>
													</td>
													<td>
														{!item.product_id &&
															<div className="table-action-button table-action-danger-button"
																onClick={() => deleteGroupProduct(index, 0)}>
																<i className="uil uil-times"></i>
															</div>
														}
													</td>
												</tr>
												{item?.groups?.map((item2, index2) => (
													index2 > 0 &&
													<tr className="border-none" key={index2}>
														<td></td>
														<td className="td-input" style={!item2.actual_balance ? { 'borderBottom': '2px solid red' } : {}}>
															<input
																type="number"
																name="actual_balance"
																className={`form-control text-end ${item2.actual_balance < 0 ? 'required-border' : ''}`}
																placeholder="0"
																value={formatMoneyInput(item2.actual_balance) ?? ''}
																onChange={(e) => handleGroupElementChange(e, index, index2)}
															/>
														</td>
														<td className="vertical-middle">{item.unit_name}</td>
														<td className="vertical-middle">
															<div className="d-flex justify-content-center">
																<div className="table-action-button table-action-danger-button"
																	onClick={() => deleteGroupProduct(index, index2)}>
																	<i className="uil uil-times"></i>
																</div>
															</div>
														</td>
													</tr>
												))
												}
											</Fragment>
											:
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>
													<ReactSelect
														isDisabled={item.product_id ? true : false}
														search={handleSelectSearch}
														value={item.name}
														index={index}
														scrollRef={scrollRef}
														options={elements}
														onClose={() => closeReactSelect()}
														selectItem={(option) => handleSelectElement(option, index)}
														onKeyUp={(e) => {
															if (e.keyCode === 40 || e.keyCode === 38) {
																handleTableKeyDown(e)
															}
														}}
														table={
															<table className="table mb-0">
																<tbody ref={tbodyRef}>
																	{elements.map((selectItem, selectIndex) => (
																		<tr
																			id={selectItem.element_id}
																			className={`cursor ${((selectItem.selected || (selectItem.element_id === item.element_id)) ? 'selected' : '')}`}
																			tabIndex={-1}
																			key={selectIndex}
																			onClick={() => handleSelectElement(selectItem, index)}
																			onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																			<td>{selectItem.name}</td>
																			<td className="text-end text-nowrap">{selectItem.product_type_name}</td>
																		</tr>
																	))}
																</tbody>
															</table>
														}
													/>
												</td>
												<td className="td-input" style={!item.actual_balance ? { 'borderBottom': '2px solid red' } : {}}>
													<input
														disabled={item.product_id}
														className={`form-control text-end ${item.actual_balance < 0 ? 'required-border' : ''}`}
														type="text"
														name="actual_balance"
														autoComplete="off"
														placeholder="0"
														value={item.actual_balance ?? ''}
														onChange={(e) => handleElementChange(e, index)}
														onKeyUp={(event) => {
															if (event.key === 'Enter') {
																addToList(1, 'element')
															}
														}}
													/>
												</td>
												<td className="vertical-middle">{item.unit_name}</td>
												<td className="vertical-middle text-end text-nowrap">{formatMoney(item.price)}</td>
												<td className="vertical-middle text-end text-nowrap">{formatMoney(item.actual_balance, 2)}</td>
												<td className="vertical-middle text-end text-nowrap">{formatMoney(item.balance, 2)}</td>
												<td className="text-end text-nowrap">
													<span className={`${item.divergence < 0 ? 'text-danger' : ''}`}>
														{formatMoney(item.divergence, 2)}
													</span>
												</td>
												<td className="text-end text-nowrap">
													<span className={`${item.difference_amount < 0 ? 'text-danger' : ''}`}>
														{formatMoney(item.difference_amount)}
													</span>
												</td>
												<td className="vertical-middle">
													<div className="d-flex justify-content-center gap-2">
														<button className="table-action-button table-action-primary-button"
															onClick={() => addGroupToList(item, index)}>
															<i className="uil uil-plus"></i>
														</button>
													</div>
												</td>
												<td className="vertical-middle">
													{!item.product_id &&
														<div className="d-flex justify-content-center gap-2">
															<button className="table-action-button table-action-danger-button"
																onClick={() => deleteElement(index)}>
																<i className="uil uil-times"></i>
															</button>
														</div>
													}
												</td>
											</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												tabIndex={-1}
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^\d]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={!data.pos_id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity, 'element')
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>

							<div className="btn-wrapper gap-2 mt-5">
								<button type="button" className="btn btn-success btn-rounded btn-wide"
									disabled={!data.elements.length > 0}
									onClick={() => setModalConfirm(true)}>
									{t('complete')}
								</button>
								<button type="button" className="btn btn-primary btn-rounded btn-wide d-none"
									disabled={!data.elements.length > 0}
									onClick={() => inventoryCompleted()}>
									{t('save_to_draft')}
								</button>
							</div>
						</div>
					</div>
				</>
			}

			<Modal show={modalStep} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalStep(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					После перехода на следующий шаг вы не сможете изменить кол-во заготовок.
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => toggleModalStep(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => confirmStep()}>
							{t('continue')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/* MODAL CONFIRM */}
			<Modal show={modalConfirm} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body></Modal.Body>
				<Modal.Footer>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalConfirm(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => inventoryCompleted(true)}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL CONFIRM */}
		</>
	)
}

export default Main