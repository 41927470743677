import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { httpOk, GET, POST } from 'helpers/api'
import { formatMoney } from 'helpers/helpers';

function Dashboard() {
	const { t } = useTranslation()

	const [show] = useState(!false)
	const [loader, setLoader] = useState(true)
	const [topData, setTopData] = useState({})
	const [settingsPartner, setSettingsPartner] = useState({})

	async function getTopData() {
		const response = await GET('/partner/dashboard')
		if (httpOk(response)) {
			setTopData(response.data)
			setLoader(false)
		}
	}

	function handleChangeSettingsPartner(e) {
		var settingsPartnerCopy = { ...settingsPartner }

		settingsPartnerCopy[e.target.name] = e.target.value

		setSettingsPartner(settingsPartnerCopy)
	}

	async function updateSettingsPartner() {
		await POST('/user-settings-partner', settingsPartner, { loader: true })
	}

	useEffect(() => {
		getTopData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{show &&
				<>
					{!loader &&
						<>
							<div className="row">
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-primary-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('balance')}</h6>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.balance)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-success-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('income')}</h6>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.overall_earned)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-danger-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('paid')}</h6>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.overall_payed)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 mb-3">
									<div className="card h-100 bg-warning-gradient">
										<div className="px-3 pt-3 pb-2 pt-0">
											<div className="d-flex justify-content-between">
												<h6 className="mb-3 tx-12 text-white">{t('poses')}</h6>
											</div>
											<div className="d-flex text-white">
												<div className="w-75">
													<h4 className="text-nowrap text-white">
														<b>{formatMoney(topData.count_pos)}</b>
													</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					}
					{loader &&
						<>
							<div className="row mb-3">
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
							</div>
						</>
					}

					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-md-6">
									<h3>Настройки</h3>
									<div className="d-flex justify-content-between mb-2">
										<div className="fw-500 vertical-center">
											ID телеграм пользователя
										</div>
										<div className="w-50">
											<input type="number" className="form-control" name="telegram_user_id"
												value={settingsPartner.telegram_user_id ?? ''}
												onChange={(e) => handleChangeSettingsPartner(e, 'text')} />
										</div>
									</div>
									<div className="d-flex justify-content-end">
										<button className="btn btn-primary"
											onClick={() => updateSettingsPartner()}>
											{t('save')}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			}
		</>
	)
}

export default Dashboard