import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { httpOk, GET } from 'helpers/api'

function Index({ showRightBar, setShowRightBar, addProduct }) {
	const { t } = useTranslation()

	const [products, setProducts] = useState([])
	const [productsDuplicate, setProductsDuplicate] = useState([])

	function search(value) {
		if (value === '') {
			setProducts(productsDuplicate)
			return;
		}

		var productsDuplicateCopy = [...productsDuplicate]
		var result = productsDuplicateCopy.filter(item =>
			item?.name?.toString().toLowerCase().includes(value.toLowerCase()) ||
			item?.menu_name?.toString().toLowerCase().includes(value.toLowerCase())
		)

		setProducts(result)
	}

	function toggleRightBar() {
		search('')
		setShowRightBar(false)
	}

	async function getProducts() {
		const response = await GET('/products-list?status=1')
		if (httpOk(response)) {
			setProducts(response.data)
			setProductsDuplicate(response.data)
		}
	}

	useEffect(() => {
		getProducts()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{showRightBar &&
				<>
					<div className="right-sidebar-background"
						onClick={() => toggleRightBar()}>
					</div>

					<div className="right-sidebar">
						<div className="position-relative">
							<div className="rightbar-close-icon"
								onClick={() => toggleRightBar()}>
								<i className="uil uil-times"></i>
							</div>
						</div>
						<div className="p-2">
							<h3 className="mb-3">{t('products')}</h3>

							<input type="text" className="form-control mb-3"
								placeholder={t('search')}
								onChange={(e) => search(e.target.value)} />
							<div className="table-responsive" style={{ 'height': '600px' }}>
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '60%' }}>{t('name2')}</th>
											<th style={{ 'width': '40%' }}>{t('menu')}</th>
											<th style={{ 'minWidth': '33px' }} className="text-center"></th>
										</tr>
									</thead>
									<tbody>
										{products?.map((item, index2) => (
											<tr key={index2}>
												<td className="text-center">{index2 + 1}</td>
												<td>{item.name}</td>
												<td>{item.menu_name}</td>
												<td>
													<div className="table-action-button table-action-success-button"
														onClick={() => addProduct(item)}>
														<i className="uil-plus"></i>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>

					</div>
				</>
			}
		</>
	)
}

export default Index