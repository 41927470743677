import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import { GET, POST, PUT, DELETE, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnMinDate, formatMoneyInput } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [wms, setWms] = useState([])
	const [addQuantity, setAddQuantity] = useState(1)
	const [items, setItems] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [modalTemplate, setModalTemplate] = useState({ 'bool': false, 'templates': [] })
	const [data, setData] = useState({
		"items": [],
		"total_amount": 0,
		"note": null,
		"activeIndex": null,
		"created_at": formatDate(new Date(), 'yyyy-MM-dd'),
	})

	async function createUpdate() {
		var dataCopy = { ...data }

		if (dataCopy.items.length === 0) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.items.length; i++) {
			if (!dataCopy.items[i]['quantity']) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.items.map(function (item) { return item.id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		var response;
		if (dataCopy.id) {
			response = await PUT('/documents-preparation', dataCopy, { loader: true })
		} else {
			response = await POST('/documents-preparation', dataCopy, { loader: true })
		}

		if (httpOk(response)) {
			navigate('/documents-preparation')
		}
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.items.push({
				'price': '',
				'quantity': '',
				'element_id': ''
			})
		}
		countTotalAmount(dataCopy)

		setTimeout(() => {
				const byId = document.getElementById(`input-select-${dataCopy.items.length}`)
			byId.select()
		}, 200);
	}

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.items.splice(index, 1)
		dataCopy.activeIndex = null

		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy.total_quantity = 0
		dataCopy.total_amount = 0
		for (let i = 0; i < dataCopy.items.length; i++) {
			dataCopy.total_quantity += Number(dataCopy.items[i]['quantity'])
			dataCopy.total_amount += Number(dataCopy.items[i]['quantity']) * Number(dataCopy.items[i]['cost_price'])
		}

		setData(dataCopy);
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.items[index][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		if (dataCopy.items[index]["bool_loss"] && e.target.name === "quantity") {
			dataCopy.items[index]["loss"] = dataCopy.items[index]["quantity"] / 100 * dataCopy.items[index]["loss_percentage"]
		}

		countTotalAmount(dataCopy)
	}

	function returnIngredients() {
		if (
			data.activeIndex !== null &&
			data.activeIndex >= 0 &&
			data.items[data.activeIndex]['ingredients'] &&
			data.items[data.activeIndex]['ingredients'].length
		) {
			return data.items[data.activeIndex]['ingredients']
		} else {
			return []
		}
	}

	// REACT SELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) return
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setItems(filteredItems)
	}

	async function handleSelectItem(option, index) {
		var dataCopy = { ...data }
		dataCopy.items[index]['id'] = option.id
		dataCopy.items[index]['name'] = option.name
		dataCopy.items[index]['balance'] = option.balance
		dataCopy.items[index]['unit_name'] = option.unit_name
		dataCopy.items[index]['cost_price'] = Number(option.cost_price)
		dataCopy.items[index]['in_product'] = option.in_product
		dataCopy.items[index]['write_off_type'] = option.write_off_type

		const response = await GET(`/documents-preparation-ingredients?product_id=${option.id}`, {}, { loader: true })
		if (httpOk(response)) {
			dataCopy.items[index]['ingredients'] = response.data
			countTotalAmount(dataCopy)
		}
	}

	function closeMenu() {
		setTimeout(() => {
			var elementsCopy = [...items]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i].selected = false
			}
			elementsCopy[0].selected = true
			setItems(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = items.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...items]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[items.length - 1].selected = true
						currentIndex = items.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === items.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setItems(elementsCopy)
		}
	}
	// REACT SELECT LOGIC

	// TEMPLATE LOGIC

	async function toggleModalTemplate(bool) {
		if (bool) {
			const response = await GET(`/documents-preparation-templates?wms_id=${data.wms_id}`, {}, { loader: true })
			if (httpOk(response)) {
				setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': response })
			}
		} else {
			setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': [] })
		}
	}

	function handleSelectTemplate(item) {
		setData({ ...data, items: item.items })
		toggleModalTemplate(false)
	}

	async function deleteTemplate(item) {
		await DELETE(`/documents-preparation-templates/${item.document_order_id}`, {}, { loader: true })
		toggleModalTemplate(false)
	}
	// TEMPLATE LOGIC

	async function getWms() {
		const response = await GET(`/helper/wms?pos_id=${reduxAccount.pos_id}`, {}, { loader: true })
		if (httpOk(response)) setWms(response.data)
	}

	async function getProducts(wmsId) {
		const response = await GET(`/documents-preparation-products?wms_id=${wmsId}`, {}, { loader: true })
		if (httpOk(response)) {
			setItems(response.data)
			setElementsDuplicate(response.data)
		}
	}

	useEffect(() => {
		getWms()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('preparation')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
				<div className="d-flex gap-1">
					<button type="button" className="btn btn-primary btn-rounded"
						onClick={() => toggleModalTemplate(true)}>
						{t('templates')}
					</button>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('date')}<span className="required-mark">*</span></label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.created_at ? new Date(data.created_at) : ''}
											onChange={(date) => setData({ ...data, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('from_warehouse')}<span className="required-mark">*</span></label>
									<Select
										options={wms}
										value={wms.find(option => option.id === data.from_wms_id)}
										onChange={(option) => {
											setData({ ...data, 'from_wms_id': option.id })
											getProducts(option.id)
										}}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('to_warehouse')}<span className="required-mark">*</span></label>
									<Select
										options={wms}
										value={wms.find(option => option.id === data.to_wms_id)}
										onChange={(option) => setData({ ...data, 'to_wms_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control"
										name="note"
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card" style={{ 'height': 'calc(100vh - 240px)' }}>
					<div className="card-body">
						<div style={{ 'height': '45%', 'overflow': 'auto' }}>
							<table className="table table-bordered" style={{ 'overflow': 'unset' }}>
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '50%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
										<th style={{ 'width': '5%' }} className="text-nowrap">{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-end">
											{t('quantity')}<span className="required-mark">*</span>
										</th>
										<th style={{ 'width': '10%' }} className="text-end">
											{t('cost_price')}
										</th>
										<th style={{ 'width': '20%' }} className="text-end">{t('amount')}</th>
										<th style={{ 'minWidth': '33px' }} className="text-end"></th>
									</tr>
								</thead>
								<tbody>
									{data?.items?.map((item, index) => (
										<tr key={index}>
											<td className="text-center"
												style={{ 'background': data.activeIndex === index ? '#c1c1c1' : '' }}
												onClick={() => setData({ ...data, 'activeIndex': index })}>
												{index + 1}
											</td>
											<td onClick={() => setData({ ...data, 'activeIndex': index })}>
												<ReactSelect
													search={handleSelectSearch}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={items}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDown(e)
														}
													}}
													onClose={() => closeMenu()}
													selectItem={(option) => handleSelectItem(option, index)}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{items.map((item2, index2) => (
																	<tr
																		id={item2.id}
																		className={"cursor " + ((item2.selected || item2.id === item.element_id) ? 'selected' : '')}
																		tabIndex={-1}
																		key={index2}
																		onClick={() => handleSelectItem(item2, index)}
																		onKeyDown={(e) => handleTableKeyDown(e, item2, index)}>
																		<td style={{ 'width': '50%' }}>{item2.name}</td>
																		<td style={{ 'width': '25%' }} className="text-end text-nowrap">{item2.product_type_name}</td>
																		<td style={{ 'width': '25%' }} className="text-end text-nowrap">{formatMoney(item2.cost_price)}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											</td>
											<td className="text-end"
												onClick={() => setData({ ...data, 'activeIndex': index })}>
												{formatMoney(item.balance)}
											</td>
											<td onClick={() => setData({ ...data, 'activeIndex': index })}>
												{item.unit_name}
											</td>
											<td className="td-input"
												style={!item.quantity ? { 'borderBottom': '2px solid red' } : {}}
												onClick={() => setData({ ...data, 'activeIndex': index })}>
												<input
													type="text"
													name="quantity"
													className="form-control text-end"
													placeholder="0"
													value={formatMoneyInput(item.quantity) ?? ''}
													onChange={(e) => handleInputChange(e, index)} />
											</td>
											<td className="text-end"
												onClick={() => setData({ ...data, 'activeIndex': index })}>
												{formatMoney(item.cost_price)}
											</td>
											<td className="text-end text-nowrap vertical-middle"
												onClick={() => setData({ ...data, 'activeIndex': index })}>
												{formatMoney(item.quantity * item.cost_price)}
											</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-danger-button"
														onClick={() => deleteItem(index)}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))}
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												placeholder="xxx"
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={!data.from_wms_id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity)
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td className="text-end">
											<b>{formatMoney(data.total_quantity)}</b>
										</td>
										<td></td>
										<td className="text-end">
											<b>{formatMoney(data.total_amount)}</b>
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="position-relative" style={{ 'height': '3%' }}>
							<span style={{ 'position': 'absolute', 'top': '-10px' }}>{t('ingredients')}</span>
							<hr />
						</div>
						<div style={{ 'height': '45%', 'overflow': 'auto' }}>
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '20%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }}>{t('type')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
										<th style={{ 'width': '10%' }}>{t('unit')}</th>
										<th style={{ 'width': '20%' }} className="text-end">{t('unit_cost')}</th>
										<th style={{ 'width': '20%' }} className="text-end">{t('total_cost_price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
									</tr>
								</thead>
								<tbody>
									{returnIngredients().map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>{item.name}</td>
											<td>{item.product_type_name}</td>
											<td className="text-end">{formatMoney(item.quantity, 2)}</td>
											<td>{item.unit_name}</td>
											<td className="text-end">{formatMoney(item.cost_price, 2)}</td>
											<td className="text-end">{formatMoney(item.cost_price * item.quantity, 2)}</td>
											<td className="text-end">{formatMoney(item.balance, 2)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="d-flex justify-content-end gap-2">
							<Link to="/documents-preparation"
								className="btn btn-warning btn-rounded btn-wide"
								tabIndex="-1">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								onClick={() => createUpdate(1)}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form>

			{/* MODAL TEMPLATE */}
			<Modal show={modalTemplate.bool} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalTemplate(false)
				}>
				<Modal.Header closeButton>
					<Modal.Title>{t('templates')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '10px' }}>#</th>
									<th>{t('name2')}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{modalTemplate?.templates?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td onClick={() => handleSelectTemplate(item)}>
											{item.name}
										</td>
										<td>
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteTemplate(item)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TEMPLATE */}
		</>
	)
}

export default CreateUpdate