export function SET_ACCOUNT(payload) {
	return {
		type: 'SET_ACCOUNT',
		payload: payload
	};
}

export function CHANGE_ACCOUNT(payload) {
	return {
		type: 'CHANGE_ACCOUNT',
		payload: payload
	};
}