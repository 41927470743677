import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { httpOk, GET } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterUrl = "/chain/telegram-bots"

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('telegram_bots')} [{data.length}]
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary"
						to={`/external-telegram-bots/create`}>
						{t('create')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('name2')}</th>
									<th style={{ 'width': '15%' }}>{t('brand_name')}</th>
									<th style={{ 'width': '70%' }}>URL</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>
											<Link to={`/external-telegram-bots/update/${item.id}`}
												target="_blank" rel="noopener noreferrer">
												{item.name}
											</Link>
										</td>
										<td>{item.brand_name}</td>
										<td className="td-ellipsis">{`https://tg.mison.uz/${item.uuid}`}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index