import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Select from 'react-select'

import { httpOk, GET, PUT, FILE, POST } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const languages = [
		{ 'id': 'ru', 'name': t('russian') }
	]

	const [data, setData] = useState({
		'name': '',
		'description': '',
		'delivery_rule_id': '',
		'poses': [],
	})
	const [deliveryRules, setDeliveryRules] = useState([])
	const [paymentTypes, setPaymentTypes] = useState([])

	async function searchWithFilter() {
		var filterUrl = `/chain/telegram-bots/${id}`

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			const responsePoses = await GET('/helper/poses')
			if (responsePoses.data.length !== response.data.poses) {
				let missingItems = responsePoses.data.filter(item => !response.data.poses.some(existingItem => existingItem.id === item.id));
				response.data.poses.push(...missingItems);
			}

			setData(response.data);
		}
	}

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (data.id) {
			response = await PUT('/chain/telegram-bots', data, { loader: true })
		} else {
			response = await POST('/chain/telegram-bots', data, { loader: true })
		}

		if (httpOk(response)) navigate(-1)
	}

	function handleDataChange(e, type) {
		var dataCopy = { ...data }

		if (type === 'checkbox') {
			dataCopy[e.target.name] = e.target.checked
		}

		if (type === 'click') {
			dataCopy['start_video'] = ""
		}

		setData(dataCopy)
	}

	function handleChangePos(e, index) {
		var dataCopy = { ...data }

		dataCopy['poses'][index]['status'] = e.target.checked
		setData(dataCopy)
	}

	function handleChangeInput(e, index) {
		var dataCopy = { ...data }

		dataCopy['poses'][index][e.target.name] = e.target.value.replace(/[^0-9.-]/g, '')
		setData(dataCopy)
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("video", e.target.files[0]);

		var response = await FILE('/upload/telegram-video', formData, {}, { loader: true });
		if (httpOk(response)) {
			var dataCopy = { ...data }
			dataCopy.settings.telegram_bot_start_video = response.data.url
			dataCopy.settings.telegram_bot_start_video_file_id = ""
			setData(dataCopy)
		}
	}

	async function getDeliveryRules() {
		const response = await GET('/helper/delivery-rules')
		if (httpOk(response)) setDeliveryRules(response.data)
	}

	async function getPaymentTypes() {
		const response = await GET('/helper/payment-types')
		if (httpOk(response)) setPaymentTypes(response.data)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setData({ ...data, 'poses': response.data })
	}

	useEffect(() => {
		if (id) searchWithFilter()
		if (!id) getPoses()
		getDeliveryRules()
		getPaymentTypes()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('telegram_bots')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1" onClick={createUpdate}>
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="row">
							<div className="col-md-6">
								<div className="d-flex gap-2">
									<div className="form-group w-100">
										<label>{t('name2')}<span className="required-mark">*</span></label>
										<input type="text" className="form-control" name="name"
											value={data.name ?? ''}
											onChange={(e) => setData({ ...data, 'name': e.target.value })} />
									</div>
									<div className="form-group w-100">
										<label>{t("brand_name")}</label>
										<input type="text" className="form-control" name="brand_name"
											value={data.brand_name ?? ''}
											onChange={(e) => setData({ ...data, 'brand_name': e.target.value })} />
									</div>
								</div>
								<div className="d-flex gap-2">
									<div className="form-group w-100">
										<label>Токен бота<span className="required-mark">*</span></label>
										<input type="text" className="form-control" name="telegram_bot_token"
											value={data.telegram_bot_token ?? ''}
											onChange={(e) => setData({ ...data, 'telegram_bot_token': e.target.value })} />
									</div>
									<div className="form-group w-100">
										<label>ID группы для жалоб</label>
										<input type="text" className="form-control" name="telegram_group_id"
											value={data.telegram_group_id ?? ''}
											onChange={(e) => setData({ ...data, 'telegram_group_id': e.target.value })} />
									</div>
								</div>
								<div className="d-flex gap-2">
									<div className="form-group w-100">
										<label>Ресторан загрузки цен<span className="required-mark">*</span></label>
										<Select
											options={data.poses}
											value={data.poses.find(option => option.id === data.pos_id) || ''}
											onChange={(option) => setData({ ...data, 'pos_id': option ? option.id : null })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group w-100">
										<label>{t('delivery_rules')}<span className="required-mark">*</span></label>
										<Select
											options={deliveryRules}
											value={deliveryRules.find(option => option.id === data.delivery_rule_id) || ''}
											onChange={(option) => setData({ ...data, 'delivery_rule_id': option ? option.id : null })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								</div>
								<div className="d-flex gap-2">
									<div className="form-group w-100">
										<label>Язык</label>
										<Select
											options={languages}
											value={languages.find(option => option.id === data.language) || ''}
											onChange={(option) => setData({ ...data, 'language': option ? option.id : null })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group w-100">
										<label>СМС Сервис</label>
										<Select
											options={[]}
											value={[].find(option => option.id === data.delivery_rule_id) || ''}
											onChange={(option) => setData({ ...data, 'delivery_rule_id': option ? option.id : null })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
								</div>

								<div className="form-group w-100">
									<label>{t('payment_types')}</label>
									<Select
										isMulti
										options={paymentTypes}
										value={data.payment_types}
										onChange={(options) => setData({ ...data, 'payment_types': options })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>

								<div className="form-group">
									<label>Видео при старте бота</label>
									{data?.start_video &&
										<div className="d-flex gap-2">
											<input type="text" className="form-control" name="start_video"
												disabled
												value={data?.start_video ?? ''} />
											<div className="btn btn-danger"
												onClick={(e) => handleDataChange(e, 'click', 'start_video')}>
												<i className="uil uil-times"></i>
											</div>
										</div>
									}
									{!data?.start_video &&
										<input type="file" className="form-control" name="start_video"
											placeholder=""
											onChange={(e) => fileUpload(e)} />
									}
								</div>

								<div className="d-flex justify-content-between mb-2">
									<div className="fw-500 vertical-center">
										Оценка заказа
									</div>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input"
											name="telegram_rating"
											checked={data.telegram_rating ? true : false}
											onChange={(e) => handleDataChange(e, 'checkbox')} />
									</div>
								</div>

								<div className="d-flex justify-content-between mb-2">
									<div className="fw-500 vertical-center">
										ОТП код при регистрации
									</div>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input"
											name="otp_code"
											checked={data.otp_code ? true : false}
											onChange={(e) => handleDataChange(e, 'checkbox')} />
									</div>
								</div>

								<div className="d-flex justify-content-between mb-2">
									<div className="fw-500 vertical-center">
										Отправлять локацию клиента в группу
									</div>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input"
											name="is_send_location"
											checked={data.is_send_location ? true : false}
											onChange={(e) => handleDataChange(e, 'checkbox')} />
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<label>{t('poses')}</label>
								<div className="table-responsive">
									<table className="table table-bordered mb-0">
										<thead>
											<tr>
												<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
												<th style={{ 'width': '20%' }}>{t('name2')}</th>
												<th style={{ 'width': '30%' }}></th>
												<th style={{ 'width': '20%' }}>Telegram ID</th>
												<th style={{ 'width': '20%' }} className="text-nowrap">{t('distance')} m</th>
												<th style={{ 'width': '10%' }}></th>
											</tr>
										</thead>
										<tbody>
											{data?.poses?.map((item, index) => (
												<tr key={index}>
													<td style={{ 'width': '33px' }} className="text-center">{index + 1}</td>
													<td className="td-ellipsis">{item.name}</td>
													<td></td>
													<td className="td-input">
														<input
															type="text"
															name="telegram_group_id"
															className="form-control"
															value={item.telegram_group_id ?? ''}
															onChange={(e) => handleChangeInput(e, index)} />
													</td>
													<td className="td-input">
														<input
															type="text"
															name="delivery_distance"
															className="form-control"
															placeholder="0"
															value={item.delivery_distance ?? ''}
															onChange={(e) => handleChangeInput(e, index)} />
													</td>
													<td className="d-flex justify-content-end">
														<div className="form-check form-switch form-switch-sm">
															<input type="checkbox" className="form-check-input"
																checked={item.status ? true : false}
																onChange={(e) => handleChangePos(e, index)} />
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div className="btn-wrapper gap-2">
							<Link className="btn btn-warning btn-rounded btn-wide" to="/external-telegram-bots">
								{t('cancel')}
							</Link>
							<button type="submit" className="btn btn-primary btn-rounded btn-wide">
								{t('save')}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default Index