import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import DeleteModal from 'pages/parts/DeleteModal'
import useDidMountEffect from 'pages/parts/useDidMountEffect'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { httpOk, GET, DELETE } from 'helpers/api'
import { formatDate, formatMoney, returnSign, findKeyFromArrayByValue, arrayJoin } from "helpers/helpers"
import { SET_FILTER_DATA } from "store/filterData/actions"
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const reduxFilterData = useSelector(state => state.filterData)
	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'start_date': formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		'end_date': formatDate(new Date(), 'yyyy-MM-dd'),
		'pos_id': null,
		'status': 1,
		'orderIds': [],
		'search': '',
	})
	const [pagination, setPagination] = useState({
		url: '',
		response: null
	})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })

	async function searchWithFilter(settings = { reduxData: false }) {
		var filterUrl = "/documents-orders"
		var urlParams = ""
		checkFilter()

		var filterDataCopy = {}
		if (settings?.reduxData) {
			filterDataCopy = reduxFilterData
			urlParams = ""
		} else {
			filterDataCopy = { ...filterData }
			urlParams = ""
		}

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		if (`/documents-orders${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function exportExcel() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/documents-orders-excel"
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.orderIds.length)
			urlParams += returnSign(urlParams) + 'document_order_ids=' + arrayJoin(filterDataCopy.orderIds, false)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			// all
			var excelHeadersAll = [[
				'#',
				t('name2'),
				t('quantity'),
				t('unit'),
				t('price'),
				t('total'),
			]]

			var totalAmountAll = 0
			for (let i = 0; i < response.data?.all?.length; i++) {
				var item = response.data.all[i]
				var excelItemsAll = []
				excelItemsAll.push(i + 1)
				excelItemsAll.push(item.name)
				excelItemsAll.push(Number(item.quantity))
				excelItemsAll.push(item.unit_name)
				excelItemsAll.push(Number(item.price))
				excelItemsAll.push(Number(item.total_price))
				excelHeadersAll.push(excelItemsAll)
				totalAmountAll += Number(item.total_price)
			}
			excelHeadersAll.push(['', t('total'), '', '', '', totalAmountAll])
			excelHeadersAll.push(['', '', '', '', '', ''])
			excelHeadersAll.push(['', '', '', '', '', ''])
			excelHeadersAll.push(['', t('note'), '', '', '', ''])
			for (let i = 0; i < response.data?.orders?.length; i++) {
				if (response.data?.orders[i]['note'])
					excelHeadersAll.push(['', `${response?.orders[i]['short_name']}: ${response.data?.orders[i]['note']}`, '', '', '', ''])
			}

			const wsAll = XLSX.utils.aoa_to_sheet(excelHeadersAll);
			var wchAll = [
				{ wch: 3 },
				{ wch: 30 },
				{ wch: 10 },
				{ wch: 10 },
				{ wch: 15 },
				{ wch: 15 },
			];
			wsAll['!cols'] = wchAll;
			// all

			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, wsAll, t('all'));

			for (let i = 0; i < response.data?.poses?.length; i++) {
				var totalAmountPos = 0
				var excelHeadersPos = [[
					'#',
					t('name2'),
					t('quantity'),
					t('unit'),
					t('price'),
					t('total'),
				]]

				for (let j = 0; j < response.data.poses[i]['elements'].length; j++) {
					var element = response.data.poses[i]['elements'][j]
					var excelItemsPos = []
					excelItemsPos.push(j + 1)
					excelItemsPos.push(element.name)
					excelItemsPos.push(Number(element.quantity))
					excelItemsPos.push(element.unit_name)
					excelItemsPos.push(Number(element.price))
					excelItemsPos.push(Number(element.total_price))
					excelHeadersPos.push(excelItemsPos)
					totalAmountPos += Number(element.total_price)
				}
				excelHeadersPos.push(['', t('total'), '', '', '', totalAmountPos])

				var wsPos = XLSX.utils.aoa_to_sheet(excelHeadersPos);
				var wchPos = [
					{ wch: 3 },
					{ wch: 30 },
					{ wch: 10 },
					{ wch: 15 },
					{ wch: 15 },
				];
				wsPos['!cols'] = wchPos;
				XLSX.utils.book_append_sheet(wb, wsPos, response.data.poses[i]['short_name']);
			}
			XLSX.writeFile(wb, t('orders') + ".xlsx");
		}
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.orderIds = []
		if (e.target.checked) {
			for (let i = 0; i < data.data.length; i++) {
				filterDataCopy.orderIds.push(data.data[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.orderIds.push(id)
		} else {
			filterDataCopy.orderIds = filterDataCopy.orderIds.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	async function deleteItem() {
		const response = await DELETE(`/documents-orders/${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: filterData.pos_id ?? '',
			orderIds: [],
			search: '',
		})
		dispatch(SET_FILTER_DATA({}))
		searchWithFilter()
	}

	function checkFilter() {
		if (filterData.search === '') {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		var response = await GET('/helper/poses', {}, { loader: true })
		if (httpOk(response)) {
			if (reduxAccount.role === "ROLE_OWNER") response.data.unshift({ 'id': -1, 'name': t('all') })
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('orders')} [{data.total}]
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/documents-orders/create">
						{t('create')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							{reduxAccount.role === "ROLE_OWNER" &&
								<div>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => exportExcel()}>
										<CustomTooltip text={t('EXCEL')}>
											<i className="uil uil-file-download-alt"></i>
										</CustomTooltip>
									</button>
								</div>
							}
						</div>
						<div className="vertical-center">
							<h4><b>{t('amount')}:</b> {formatMoney(data?.total_amount)}</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '10px' }}>
										<input type="checkbox"
											onChange={(e) => handleCheckboxChangeAll(e)} />
									</th>
									<th style={{ 'width': '33px' }} className="text-center">#</th>
									<th>{t('pos')}</th>
									<th>{t('external_supplier')}</th>
									<th className="text-center">{t('document')}</th>
									<th>{t('note')}</th>
									<th className="text-center">{t('date')}</th>
									<th className="text-end">{t('amount')}</th>
									<th style={{ 'width': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="vertical-middle">
											<input type="checkbox"
												checked={filterData?.orderIds?.includes(item.id)}
												onChange={(e) => handleCheckboxChange(e, item.id)} />
										</td>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td>{item.to_pos_name}</td>
										<td className="text-center">{item.document_number}</td>
										<td className="td-ellipsis">{item.note}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<Link className="table-dropdown-item"
															to={`/documents-orders/update/${item.id}`}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</Link>
														<Link className="table-dropdown-item"
															to={`/documents-orders/preview/${item.id}`}>
															<i className="uil-eye"></i>
															{t('preview')}
														</Link>
														{(item.status === 1 && item?.last_id && reduxAccount.role !== "ROLE_OWNER") &&
															<div className="table-dropdown-item"
																onClick={() => {
																	setDeleteModalParams({ id: item.id, index: index });
																	setModalDelete(true)
																}}>
																<i className="uil uil-times"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index