import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { FILE_D, GET, POST, httpOk } from 'helpers/api'
import { formatMoney, returnSign, findKeyFromArrayByValue, returnMinDate, formatMoneyInput, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()

	const [ppt, setPPT] = useState([])
	const [poses, setPoses] = useState([])
	const [counterparty, setCounterparty] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({
		'account_chef_id': 7,
		'created_at': date,
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id
	})
	const [pagination, setPagination] = useState({
		url: `/pos-safe`,
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = `/pos-safe`
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.expense_type)
			urlParams += returnSign(urlParams) + 'expense_type=' + filterDataCopy.expense_type
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		filterDataCopy.urlParams = urlParams

		if (`/pos-safe${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function downloadExcelFile() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/pos-safe"
		var urlParams = "?excel=true"

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.expense_type)
			urlParams += returnSign(urlParams) + 'expense_type=' + filterDataCopy.expense_type
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search

		filterDataCopy.urlParams = urlParams

		filterUrl = filterUrl + urlParams
		var response = await FILE_D(filterUrl)

		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('safe')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	async function payment(e) {
		e.preventDefault();

		var response = await POST('/pos-safe-payment', selectedItem, { loader: true })

		if (httpOk(response)) {
			togglePaymentModal(false)
			searchWithFilter()
		}
	}

	function togglePaymentModal(bool, transaction_type = 'minus') {
		var selectedItemCopy = { ...selectedItem }
		if (bool) {
			getPPT(transaction_type)
			setSelectedItem(selectedItemCopy)
		} else {
			setSelectedItem({
				'account_chef_id': 7,
				'created_at': new Date()
			})
		}

		setModalPayment(bool)
	}

	function handleSelectPPT(option) {
		setSelectedItem({
			...selectedItem,
			'payment_purpose_id': option.id,
			'account_chef_id': 7,
			'account_core_id': option.account_core_id,
		})
		getCounterparty(option)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getPPT(transaction_type) {
		if (transaction_type === 'plus') {
			const response = await GET('/helper/ppt-income')
			if (httpOk(response)) setPPT(response.data)
		}

		if (transaction_type === 'minus') {
			const response = await GET('/helper/ppt-expense')
			if (httpOk(response)) setPPT(response.data)
		}
	}

	async function getCounterparty(item) {
		if (item.counterparty === 'supplier') {
			const response = await GET('/helper/suppliers')
			if (httpOk(response)) setCounterparty(response.data)
		}

		if (item.counterparty === 'employee') {
			const response = await GET('/helper/employees')
			if (httpOk(response)) setCounterparty(response.data)
		}
	}

	function returnEntityType(item) {
		var name = ""
		if (item?.entity_type === 'supplier') {
			name = t('supplier')
		}
		if (item?.entity_type === 'employee' || item?.entity_type === 'employee_salary') {
			name = t('employee')
		}
		if (item?.entity_type === 'agent') {
			name = t('agent')
		}
		if (item?.entity_type === 'organization') {
			name = t('organization')
		}
		if (item?.entity_type === 'client') {
			name = t('client')
		}
		if (item?.entity_type === 'expense') {
			name = t('expense')
		}

		return name
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header  d-flex justify-content-between">
				<h4 className="vertical-center h-100">
					{t('safe')} [{data.total}]
				</h4>
				<DropdownButton title={t('action')}>
					<Dropdown.Item onClick={() => togglePaymentModal(true, 'minus')}>
						{t('take_money')}
					</Dropdown.Item>
					<Dropdown.Item onClick={() => togglePaymentModal(true, 'plus')}>
						{t('put_money')}
					</Dropdown.Item>
				</DropdownButton>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div>
								<button className="btn btn-outline-primary btn-wide-2"
									title={t('EXCEL')}
									onClick={downloadExcelFile}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4><b>{t('amount')}</b>: {formatMoney(data.balance)}</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{(poses.length && filterData.pos_id) &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '10%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('name')}</th>
									<th style={{ 'width': '35%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }}>{t('account')}</th>
									<th style={{ 'width': '15%' }}>{t('expense')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis" title={item.pos_name}>{item.pos_name}</td>
										<td className="td-ellipsis" title={item.created_by}>{item.first_name}	</td>
										<td className="td-ellipsis" title={item.note}>{item.note}</td>
										<td className="td-ellipsis" title={item.account_name}>{item.account_name}</td>
										<td className="d-none">{returnEntityType(item)}</td>
										<td className="td-ellipsis">{item.entity_name}</td>
										<td className="text-center">
											<span className={`${item.shift_id && 'bg-success bg-border text-white'}`}
												title={item.shift_id && t('created_by_system')}>
												{formatDate(item.created_at, 'dd.MM.yy HH:mm')}
											</span>
										</td>
										<td className="text-end">
											<span className={item.transaction_type === 'plus' ? 'text-success' : 'text-danger'}>
												{formatMoney(item.amount)}
											</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => togglePaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('working_with_safe')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('date')}</label>
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItem.created_at ? new Date(selectedItem.created_at) : ''}
									onChange={(date) => setSelectedItem({ ...selectedItem, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
									minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('payment_type')}</label>
							<Select
								options={ppt}
								value={ppt.find(option => option.id === selectedItem.payment_purpose_id) || ''}
								onChange={(option) => handleSelectPPT(option)}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('counterparty')}</label>
							<Select
								options={counterparty}
								value={counterparty.find(option => option.id === selectedItem.counterparty_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'counterparty_id': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount" autoFocus
								value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => togglePaymentModal(false)} tabIndex="-1">{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!selectedItem.amount && !selectedItem.transaction_type}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
