import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Themes from './Themes'
import Settings from './Settings'
import User from './User'
import Delivery from './Delivery'
import Courier from './Courier'
import FAQ from './FAQ'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { themes } from 'helpers/themes'
import { GET, POST, httpOk } from 'helpers/api'

const defaultSettings = {
	"online_menu_theme": themes[0].light,
	"telegram_theme": themes[0],
	"telegram_bot_type": 1, // Выбрать тип тлеграм бота кетрин или просто фаст фуд тип
	"delivery_cost_type": 1, // 1 Фиксированная 2 Динамическая за один километр
	"delivery_cost": 0, // Сумма доставки если delivery_cost_type 1
	"otp_code": true, // ОТП код при регистрации телеграмма
	"max_delivery_distance": 0, // Максимальная дистанция доставки
	"delivery_free_until_km": 0, // Бесплатно до км
	"delivery_free_after_amount": 0, // Бесплатно от суммы
	"delivery_until_km": 0, // Фикса до км км
	"delivery_amount_until_km": 0, // Фикса до км сумма
	"bool_telegram_rating": 0, // Оценка заказа 5 бальный
	"brand_name": "", // Название бренда если сеть
	"map_api_key": "",
	"theme": "light",
	"delivery_statuses": {},
	"courier_accept_order_limit_km": "", // Нельзя принимать заказ если не ближе к торговой точке км
}

function SettingLayout() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [data, setData] = useState({
		'first_name': '',
		'phone': '',
		'backdate': '',
		'secret_key': '',
		'settings': defaultSettings
	})
	const [activeTab, setActiveTab] = useState(1)

	function handleSettingChange(e, type, selectName = '') {
		var dataCopy = { ...data }
		if (type === 'checkbox') {
			dataCopy['settings'][e.target.name] = e.target.checked
		}

		if (type === 'text') {
			dataCopy['settings'][e.target.name] = e.target.value
		}

		if (type === 'number') {
			dataCopy['settings'][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		}

		if (type === 'select') {
			if (selectName === 'distance_calculation_service_id') {
				dataCopy['settings']['distance_calculation_service_id'] = e.id
			}
			if (selectName === 'sms_service_id') {
				dataCopy['settings']['sms_service_id'] = e.id
			}
		}

		if (type === 'click') {
			if (selectName === 'telegram_bot_start_video') {
				dataCopy['settings']['telegram_bot_start_video'] = ""
			}
		}

		setData(dataCopy)
	}

	function handleUserChange(e) {
		var dataCopy = { ...data }

		dataCopy[e.target.name] = e.target.value

		setData(dataCopy)
	}

	function handleThemeChange(item) {
		var dataCopy = { ...data }

		dataCopy.settings.telegram_theme = item
		if (data.theme === 'light') {
			dataCopy.settings.online_menu_theme = item.light
		} else {
			dataCopy.settings.online_menu_theme = item.dark
		}

		setData(dataCopy)
	}

	function handleThemeTypeChange(type) {
		var dataCopy = { ...data }

		dataCopy.settings.theme = type
		if (dataCopy.settings.theme === 'light') {
			dataCopy.settings.online_menu_theme = dataCopy.settings.telegram_theme.light
		} else {
			dataCopy.settings.online_menu_theme = dataCopy.settings.telegram_theme.dark
		}

		setData(dataCopy)
	}

	async function setUserSettings() {
		await POST('/user-settings', data, { loader: true })
	}

	async function getUUID() {
		var response = await GET('/helper/uuid', {}, { loader: true })
		if (httpOk(response)) {
			setData({ ...data, 'secret_key': response.data })
		}
	}

	async function getUserSettings() {
		var response = await GET('/user-settings')
		if (httpOk(response)) {
			if (!response.data.settings) {
				response.data.settings = defaultSettings
			}
			setData(response.data)
		}
	}

	useEffect(() => {
		dispatch(TOGGLE_LEFT_MENU(false))
		getUserSettings()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between">
						<div className="setting-tabs">
							<div className={"setting-tab " + (activeTab === 1 ? 'active' : '')}
								onClick={() => setActiveTab(1)}>
								Темы
							</div>
							<div className={"setting-tab " + (activeTab === 2 ? 'active' : '')}
								onClick={() => setActiveTab(2)}>
								Телеграм
							</div>
							<div className={"setting-tab " + (activeTab === 3 ? 'active' : '')}
								onClick={() => setActiveTab(3)}>
								Данные
							</div>
							<div className={"setting-tab " + (activeTab === 4 ? 'active' : '')}
								onClick={() => setActiveTab(4)}>
								Доставка
							</div>
							<div className={"setting-tab " + (activeTab === 5 ? 'active' : '')}
								onClick={() => setActiveTab(5)}>
								Курьер
							</div>
							<div className={"setting-tab " + (activeTab === 6 ? 'active' : '')}
								onClick={() => setActiveTab(6)}>
								FAQ
							</div>
						</div>
						<div>
							<button className="btn btn-primary"
								onClick={() => setUserSettings()}>
								{t('save')}
							</button>
						</div>
					</div>

					{activeTab === 1 &&
						<Themes
							data={data}
							handleThemeTypeChange={handleThemeTypeChange}
							handleThemeChange={handleThemeChange}
						/>
					}
					{activeTab === 2 &&
						<Settings
							data={data}
							setData={setData}
							handleSettingChange={handleSettingChange}
						/>
					}
					{activeTab === 3 &&
						<User
							data={data}
							setData={setData}
							handleUserChange={handleUserChange}
							getUUID={getUUID}
						/>
					}
					{activeTab === 4 &&
						<Delivery
							data={data}
							setData={setData}
							handleSettingChange={handleSettingChange}
						/>
					}
					{activeTab === 5 &&
						<Courier
							data={data}
							setData={setData}
							handleSettingChange={handleSettingChange}
						/>
					}
					{activeTab === 6 &&
						<FAQ />
					}
				</div>
			</div>
		</>
	)
}

export default SettingLayout