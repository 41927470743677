import React, { useEffect, useState, Fragment } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker"
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, formatDate, returnSign, findKeyFromArrayByValue } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const [reports] = useState([
		{ 'id': 1, 'name': `X ${t('report')}`, 'active': true },
		{ 'id': 2, 'name': `${t('waiters')}`, 'active': false },
		{ 'id': 3, 'name': `${t('menu')}`, 'active': false },
		{ 'id': 4, 'name': `${t('products')}`, 'active': false },
		{ 'id': 5, 'name': `${t('preparation_places')}`, 'active': false },
		{ 'id': 6, 'name': `${t('waiters_products')}`, 'active': false },
		{ 'id': 7, 'name': `${t('payment_types')}`, 'active': false },
		{ 'id': 8, 'name': `${t('expenses')}`, 'active': false },
	]);

	const [activeTab, setActiveTab] = useState(1)
	const [poses, setPoses] = useState([])
	const [xReportData, setXreportData] = useState({
		"menu": {},
		"waiters": {},
		"products": {},
		"x_report": {},
		"preparation_places": {},
		"expenses": {},
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'start_date': formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		'end_date': formatDate(new Date(), 'yyyy-MM-dd'),
		'pos_id': reduxAccount.pos_id,
	})

	async function searchWithFilter() {
		var filterUrl = `/report-z`
		var urlParams = ""

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date

		if (`/report-z${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setXreportData(response.data)
		}

		setDropdownIsOpen(false)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		searchWithFilter()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('z_report')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase">
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="d-flex gap-3">
						<div style={{ 'width': '30%' }}>
							<div className="right" style={{ 'overflow': 'auto' }}>
								{reports.map((item, index) => (
									<div className="d-flex justify-content-between mb-2" key={index}
										style={activeTab === item.id ? { 'borderBottom': '1px solid #000' } : {}}>
										<h6 className="w-75"
											onClick={() => setActiveTab(item.id)}>
											{item.name}
										</h6>
									</div>
								))}
							</div>
						</div>
						<div style={{ 'width': '70%' }}>
							{activeTab === 1 &&
								<div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('number_of_checks')} [ {t('hall')} ]</span>
										<span>{xReportData['x_report'].count_hall}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('number_of_checks')} [ {t('takeaway')} ]</span>
										<span>{xReportData['x_report'].count_takeaway}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<span>{t('number_of_checks')} [ {t('delivery')} ]</span>
										<span>{xReportData['x_report'].count_delivery}</span>
									</div>
									<div className="d-flex justify-content-between mb-1">
										<b>{t('number_of_checks')}</b>
										<b>{xReportData['x_report'].count_cheque}</b>
									</div>
									{!!(xReportData['x_report'].total_no_profit || xReportData['x_report'].total_no_service_percentage) &&
										<hr />
									}
									{!!xReportData['x_report'].total_no_profit &&
										<div className="d-flex justify-content-between mb-1">
											<span>{t('number_of_checks')} [ {t('no_profit')} ]</span>
											<span>{xReportData['x_report'].total_no_profit}</span>
										</div>
									}
									{!!xReportData['x_report'].total_no_service_percentage &&
										<div className="d-flex justify-content-between mb-1">
											<span>{t('number_of_checks')} [ {t('no_service_percentage')} ]</span>
											<span>{xReportData['x_report'].total_no_service_percentage}</span>
										</div>
									}
									{(xReportData && xReportData['x_report']?.payments?.length > 0) &&
										<hr />
									}
									<div className="d-flex justify-content-between fz14">
										<span>{t('sales')}</span>
										<span className="text-success">{formatMoney(xReportData['x_report']?.sales)}</span>
									</div>
									<div className="d-flex justify-content-between fz14">
										<span>{t('service')}</span>
										<span className="text-success">{formatMoney(xReportData['x_report']?.service_percentage_amount)}</span>
									</div>
									<div className="d-flex justify-content-between fz14">
										<span>{t('delivery')}</span>
										<span className="text-success">{formatMoney(xReportData['x_report']?.total_delivery_amount)}</span>
									</div>
									<div className="d-flex justify-content-between fz14">
										<b>{t('sale_amount')}</b>
										<b>{formatMoney(xReportData['x_report']?.total_sales)}</b>
									</div>
									<div className="ms-2">
										<div className="d-flex justify-content-between d-none">
											<p>{t('change')}</p>
											<p className="text-success">{formatMoney(xReportData['x_report']?.total_change)}</p>
										</div>
										<div className="d-flex justify-content-between">
											<p>{t('discount')}</p>
											<p className="text-danger">{formatMoney(xReportData['x_report']?.total_discount)}</p>
										</div>
										<div className="d-flex justify-content-between">
											<span>{t('debt')}</span>
											<span className="text-danger">{formatMoney(xReportData['x_report'].total_debt_amount)}</span>
										</div>
										{!!Number(xReportData['x_report'].total_agent_amount) > 0 &&
											<div className="d-flex justify-content-between">
												<span>{t('agents_bonus')}</span>
												<span className="text-danger">{formatMoney(xReportData['x_report'].total_agent_amount)}</span>
											</div>
										}
										{xReportData['x_report'].loyalty &&
											<div className="d-flex justify-content-between">
												<span>{t('loyalty')}</span>
												<span>{formatMoney(xReportData['x_report']?.loyalty?.amount_out)}</span>
											</div>
										}
										<div className="d-flex justify-content-between">
											<b>
												<CustomTooltip text={t(`${t('total')} = ${t('discount')} + ${t('debt')} + ${t('shortage')} + ${t('agents_bonus')}`)}>
													{t('total')}
												</CustomTooltip>
											</b>
											<b className="text-danger">
												{formatMoney(
													Number(xReportData['x_report'].total_discount) +
													Number(xReportData['x_report'].total_debt_amount) +
													Number(xReportData['x_report'].total_agent_amount)
												)}
											</b>
										</div>
									</div>
									{!!xReportData['x_report']?.total_return_debt &&
										<div className="d-flex justify-content-between fz14">
											<b>
												<CustomTooltip text={t(`${t('total_checkout')} = ${t('sale_amount')} - ${t('discount')} - ${t('agents_bonus')} - ${t('sold_on_credit')}`)}>
													{t('total_checkout_sales')}
												</CustomTooltip>
											</b>
											<b>{formatMoney(xReportData['x_report']?.total_payments - xReportData['x_report']?.total_return_debt)}</b>
										</div>
									}
									<div className="d-flex justify-content-between fz14">
										<b>
											<CustomTooltip text={t(`${t('total_checkout')} = ${t('sale_amount')} - ${t('discount')} - ${t('agents_bonus')} - ${t('sold_on_credit')} + ${t('amortization')}`)}>
												{t('total_checkout')}
											</CustomTooltip>
										</b>
										<b>{formatMoney(xReportData['x_report']?.total_payments)}</b>
									</div>
									<div className="ms-2">
										{xReportData['x_report'].loyalty &&
											<div className="d-flex justify-content-between">
												<span>{t('loyalty')}</span>
												<span>{formatMoney(xReportData['x_report']?.loyalty?.amount_in)}</span>
											</div>
										}
										{xReportData['x_report']?.payments?.map((item, index) => (
											<div className="d-flex justify-content-between" key={index}>
												<p className="d-flex flex-wrap gap-1">
													{Number(item.amount_in) !== 0 ?
														<span>&#9660;</span>
														:
														<span>&#9650;</span>
													}
													<span>{item.payment_type_name}</span>
													<span>{item.payment_purpose_name}</span>
												</p>
												{Number(item.amount_in) !== 0 ?
													<p className="text-success text-nowrap">{formatMoney(item.amount_in)}</p>
													:
													<p className="text-danger text-nowrap">{formatMoney(item.amount_out)}</p>
												}
											</div>
										))}
										{!!xReportData['x_report'].total_cash &&
											<div className="d-flex justify-content-between">
												<b>{t('total_cash')}</b>
												<b className="text-success">
													{formatMoney(xReportData['x_report'].total_cash)}
												</b>
											</div>
										}
										{!!xReportData['x_report'].total_bank &&
											<div className="d-flex justify-content-between">
												<b>{t('total_bank')}</b>
												<b className="text-success">
													{formatMoney(xReportData['x_report'].total_bank)}
												</b>
											</div>
										}
										{!!xReportData['x_report'].total_expense &&
											<div className="d-flex justify-content-between">
												<b>{t('total_expense')}</b>
												<b className="text-danger">
													{formatMoney(xReportData['x_report'].total_expense)}
												</b>
											</div>
										}
									</div>
									<hr />
									<b className="d-flex justify-content-between">
										<h5 className="color-00">
											<CustomTooltip text={t(`${t('cashbox_balance')} = ${t('cash')} + ${t('debt_returned')} - ${t('expenses')}`)}>
												{t('cashbox_balance')}
											</CustomTooltip>
										</h5>
										<h5 className="text-no-wrap color-00">
											{formatMoney(xReportData['x_report'].balance)}
										</h5>
									</b>
								</div>
							}
							{activeTab === 2 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('waiter')}</b></th>
											<th className="text-end"><b>{t('service')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['waiters']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.first_name}</td>
												<td className="text-end">{formatMoney(item.total_service_amount)}</td>
												<td className="text-end">{formatMoney(item.total_amount)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['waiters']?.total_service_amount)}</b>
											</td>
											<td className="text-end">
												<b>{formatMoney(xReportData['waiters']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 3 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('menu')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['menu']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.name}</td>
												<td className="text-end">{formatMoney(item.total_sale_price)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['menu']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 4 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('product')}</b></th>
											<th className="text-end"><b>{t('quantity')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['products']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.name}</td>
												<td className="text-end">{item.total_quantity}</td>
												<td className="text-end">{formatMoney(item.total_sale_price)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['products']?.total_quantity)}</b>
											</td>
											<td className="text-end">
												<b>{formatMoney(xReportData['products']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 5 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('preparation_places')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['preparation_places']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.name}</td>
												<td className="text-end">{formatMoney(item.total_sale_price)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['preparation_places']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 6 &&
								<Fragment>
									{xReportData?.waiters_products?.map((waiter, wIndex) => (
										<div className="mb-3" key={wIndex}>
											<b><i>{waiter.name}</i></b>
											<table className="table table-bordered table-bordered-custom">
												<thead>
													<tr>
														<th className="text-start"><b>{t('product')}</b></th>
														<th className="text-end"><b>{t('quantity')}</b></th>
														<th className="text-end"><b>{t('amount')}</b></th>
													</tr>
												</thead>
												<tbody>
													{waiter?.products?.map((item, index) => (
														<tr key={index}>
															<td className="text-start">{item.name}</td>
															<td className="text-end">{formatMoney(item.total_quantity)}</td>
															<td className="text-end">{formatMoney(item.total_amount)}</td>
														</tr>
													))}
												</tbody>
												<tfoot>
													<tr>
														<td><b>{t('total')}</b></td>
														<td className="text-end">
															<b>{formatMoney(waiter.total_quantity)}</b>
														</td>
														<td className="text-end">
															<b>{formatMoney(waiter.total_amount)}</b>
														</td>
													</tr>
												</tfoot>
											</table>
										</div>
									))}
								</Fragment>
							}
							{activeTab === 7 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start"><b>{t('name2')}</b></th>
											<th className="text-end"><b>{t('amount')}</b></th>
										</tr>
									</thead>
									<tbody>
										{xReportData['payment_types']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start">{item.name}</td>
												<td className="text-end">{formatMoney(item.amount)}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['payment_types']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
							{activeTab === 8 &&
								<table className="table table-bordered table-bordered-custom">
									<thead>
										<tr>
											<th className="text-start" style={{ 'width': '50%' }}>
												<b>{t('type')}</b>
											</th>
											<th className="text-start" style={{ 'width': '30%' }}>
												<b>{t('counterparty')}</b>
											</th>
											<th className="text-end" style={{ 'width': '20%' }}>
												<b>{t('amount')}</b>
											</th>
										</tr>
									</thead>
									<tbody>
										{xReportData['expenses']?.data?.map((item, index) => (
											<tr key={index}>
												<td className="text-start td-ellipsis">
													{item.name}
												</td>
												<td className="text-start td-ellipsis">
													{item.counterparty}
												</td>
												<td className="text-end">
													{formatMoney(item.amount)}
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td><b>{t('total')}</b></td>
											<td></td>
											<td className="text-end">
												<b>{formatMoney(xReportData['expenses']?.total_amount)}</b>
											</td>
										</tr>
									</tfoot>
								</table>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index