import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import { GET, POST, PUT, httpOk } from 'helpers/api'
import { formatDate, formatMoney, formatMoneyInput, returnMinDate } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		items: [],
		note: '',
		total_cost_price: 0,
		created_at: formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [wms, setWms] = useState([])
	const [products, setProducts] = useState([])
	const [productsDuplicate, setProductsDuplicate] = useState([])
	const [addQuantity, setAddQuantity] = useState(1)

	async function createUpdate(e, completed) {
		e.preventDefault();
		var dataCopy = { ...data }
		dataCopy.error = false
		dataCopy.completed = completed

		for (let i = 0; i < dataCopy.items.length; i++) {
			if (!dataCopy.items[i]['quantity']) {
				dataCopy.error = true
			}
			if (dataCopy.items[i]['price'] === "" || dataCopy.items[i]['price'] < 0.0001) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.items.map(function (item) { return item.id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		var response;
		if (dataCopy.id) {
			response = await PUT('/write-off', dataCopy, { loader: true })
		} else {
			response = await POST('/write-off', dataCopy, { loader: true })
		}

		if (httpOk(response)) {
			navigate('/write-off')
		}
	}

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.items.splice(index, 1)

		setData(dataCopy)
		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy['total_cost_price'] = 0
		for (let i = 0; i < dataCopy.items.length; i++) {
			dataCopy.items[i]['total_cost_price'] = 0
			dataCopy.items[i]['total_cost_price'] +=
				Number(dataCopy.items[i]['quantity']) *
				Number(dataCopy.items[i]['cost_price'])

			dataCopy['total_cost_price'] += dataCopy.items[i]['total_cost_price']
		}

		setData(dataCopy)
	}

	function addToList() {
		var dataCopy = { ...data }

		dataCopy.items.push({
			'quantity': ''
		})
		countTotalAmount(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.items[index][e.target.name] = e.target.value
		countTotalAmount(dataCopy)
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setProducts([...productsDuplicate])
			return
		}
		var productsDuplicateCopy = [...productsDuplicate]

		var filteredItems = productsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setProducts(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }

		dataCopy.items[index]['id'] = option.id
		dataCopy.items[index]['name'] = option.name
		dataCopy.items[index]['balance'] = option.balance
		dataCopy.items[index]['in_product'] = option.in_product
		dataCopy.items[index]['cost_price'] = option.cost_price
		dataCopy.items[index]['unit_name'] = option.unit_name
		dataCopy.items[index]['product_type_name'] = option.product_type_name

		countTotalAmount(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var productsCopy = [...products]
			for (let i = 0; i < productsCopy.length; i++) {
				productsCopy[i]['selected'] = false
			}

			if (productsCopy[0] && productsCopy[0]['selected'])
				productsCopy[0]['selected'] = true

			setProducts(productsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = products.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var productsCopy = [...products]
			productsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						productsCopy[products.length - 1].selected = true
						currentIndex = products.length - 1
					} else {
						productsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === products.length - 1) {
						productsCopy[0].selected = true
						currentIndex = 0
					} else {
						productsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setProducts(productsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function handleSelectWms(option) {
		setData({ ...data, 'wms_id': option.id })

		const response = await GET(`/write-off-items/${option.id}`, {}, { loader: true })
		if (httpOk(response)) {
			setProducts(response.data)
			setProductsDuplicate(response.data)
		}
	}

	async function getWms() {
		const response = await GET(`/helper/wms`, {}, { loader: true })

		if (httpOk(response)) setWms(response.data)
	}

	useEffect(() => {
		getWms()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('write_off')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('date')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.created_at ? new Date(data.created_at) : ''}
											onChange={(date) => setData({ ...data, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('warehouse')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.wms_id}
										options={wms}
										value={wms.find(option => option.id === data.wms_id)}
										onChange={(option) => handleSelectWms(option)}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										value={data.note ?? ''}
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '40%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }}>{t('type')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
										<th style={{ 'width': '10%' }}>{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
										<th style={{ 'minWidth': '33px' }}></th>
									</tr>
								</thead>
								<tbody>
									{data?.items?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>
												<ReactSelect
													search={handleSelectSearch}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={products}
													onClose={() => closeReactSelect()}
													selectItem={(option) => handleSelectElement(option, index)}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDown(e)
														}
													}}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{products.map((selectItem, selectIndex) => (
																	<tr
																		id={selectItem.id}
																		className={`cursor ${((selectItem.selected || selectItem.id === item.element_id) && 'selected')}`}
																		tabIndex={-1}
																		key={selectIndex}
																		onClick={() => handleSelectElement(selectItem, index)}
																		onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																		<td>{selectItem.name}</td>
																		<td>{selectItem.product_type_name}</td>
																		<td className="text-end text-nowrap">{formatMoney(selectItem.cost_price)}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											</td>
											<td>{item.product_type_name}</td>
											<td className="text-end">{formatMoney(item.balance, 2)}</td>
											<td className="vertical-middle">{item.unit_name}</td>
											<td className="td-input" style={!item.quantity ? { 'borderBottom': '2px solid red' } : {}}>
												<input
													type="text"
													name="quantity"
													className="form-control text-end"
													placeholder="0"
													value={formatMoneyInput(item.quantity) ?? ''}
													onChange={(e) => handleInputChange(e, index)} />
											</td>
											<td className="vertical-middle text-end">{formatMoney(item.cost_price)}</td>
											<td className="vertical-middle text-end">{formatMoney(item.total_cost_price)}</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-danger-button"
														onClick={() => deleteItem(index)}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))}
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												placeholder="xxx"
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={!data.wms_id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity)
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td className="text-end"><b>{formatMoney(data.total_cost_price)}</b></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className="btn-wrapper gap-2">
							<Link className="btn btn-warning btn-rounded btn-wide" to="/write-off">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-success btn-rounded btn-wide"
								disabled={!data.items.length > 0}
								onClick={(e) => createUpdate(e, true)}>{t('complete')}</button>
							<button type="button" className="btn btn-primary btn-rounded btn-wide"
								disabled={!data.items.length > 0}
								onClick={(e) => createUpdate(e, false)}>{t('save_to_draft')}</button>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default CreateUpdate