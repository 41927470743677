import React, { Fragment, useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET, httpOk } from "helpers/api"
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const [data, setData] = useState({})
	const [expandedItems, setExpandedItems] = useState({});

	function TreeTable({ data, level = 0, formatMoney }) {
		return (
			<>
				{data?.map((item, index) => (
					<React.Fragment key={item.id}>
						<tr>
							<td className="text-center">
								{!item.product_parent_id &&
									<span>{index + 1}</span>
								}
							</td>
							<td style={{ 'paddingLeft': `${level * 10 + 10}px` }}
								onClick={(e) => {
									e.stopPropagation(); // Остановить всплытие события клика
									toggleExpand(item.id); // Переключить состояние "открыт/закрыт"
								}}>
								{!item.product_parent_id &&
									<span className={item?.children?.length ? `product-parent` : ''}>{item.product_name}</span>
								}
								{item.product_parent_id &&
									<i className={item?.children?.length ? `product-parent` : ''}>{item.product_name}</i>
								}
							</td>
							<td>{item.product_type_name}</td>
							<td></td>
							<td className="text-end">{formatMoney(item.cost_price)}</td>
							<td className="text-end">{item.quantity}</td>
							<td className="text-end">{formatMoney(item.cost_price * item.quantity)}</td>
						</tr>

						{/* Рекурсивный вызов для children */}
						{expandedItems[item.id] && item.children && item.children.length > 0 && (
							<TreeTable
								data={item.children}
								level={level + 1}
								formatMoney={formatMoney}
							/>
						)}
					</React.Fragment>
				))}
			</>
		);
	}

	const toggleExpand = (id) => {
		setExpandedItems((prev) => ({
			...prev,
			[id]: !prev[id], // Переключаем состояние для текущего элемента
		}));
	};

	async function getData() {
		const response = await GET(`/write-off/${id}`, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps 

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('write_off')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('document_from')} {formatDate(data.created_at)}</h4>
					<div className="flex-column preview-info">
						<div className="d-flex">
							<div className="fw-600" style={{ 'width': '150px' }}>{t('pos')}:</div>
							<div>{data.pos_name}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600" style={{ 'width': '150px' }}>{t('warehouse')}:</div>
							<div>{data.wms_name}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600" style={{ 'width': '150px' }}>{t('document')}:</div>
							<div>{data.document_number}</div>
						</div>
						<div className="d-flex">
							<div className="fw-600" style={{ 'width': '150px' }}>{t('note')}:</div>
							<div>{data.note}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('type')}</th>
									<th style={{ 'width': '30%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								<TreeTable
									data={data.items}
									formatMoney={formatMoney}
								/>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td className="text-end"><b>{formatMoney(data.total_cost_price)}</b></td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="d-flex justify-content-end">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('cancel')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Preview