import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker"
import { Dropdown } from 'react-bootstrap'
import { startOfMonth, endOfMonth, format } from 'date-fns'
import Select from 'react-select'
import XLSX from 'xlsx'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import SummaryLoyalty from 'pages/main/reports/SummaryLoyalty'
import SummaryExpense from 'pages/main/reports/SummaryExpense'
import SummaryIncomes from 'pages/main/reports/SummaryIncomes'
import SummaryDebt from 'pages/main/reports/SummaryDebt'
import SummaryCash from 'pages/main/reports/SummaryCash'
import SummaryBank from 'pages/main/reports/SummaryBank'
import SummarySalary from 'pages/main/reports/SummarySalary'
import SammarySafe from 'pages/main/reports/SammarySafe'
import SummarySales from 'pages/main/reports/SummarySales'
import SummaryGrossIncome from 'pages/main/reports/SummaryGrossIncome'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Summary() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [overall, setOverall] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		plan: null,
		type: null,
		random: 0,
		random_active_modal: 0,
	})
	const [activeModal, setActiveModal] = useState(0)

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = `/report-summary`
		var urlParams = ""

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setOverall(response.data)
			setData(response.data.data)
		}

		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		var excelHeaders = [[
			t('date'), t('good_reception'), t('paid_supplier'), t('sales'), t('gross_income'), t('discount'), t('bonus'),
			t('expense'), t('salary'), t('prepaid_expense'), t('salary_balance'),
			t('cash'), t('bank'), t('debt'), t('debt_returned'), t('cashbox_balance'), t('net_profit')
		]]

		for (let i = 0; i < data.length; i++) {
			var excelItems = []
			excelItems.push(data[i].days)
			excelItems.push(Number(data[i]['documents_in_total_amount']))
			excelItems.push(Number(data[i]['supplier_total_amount']))
			excelItems.push(Number(data[i]['sales_total_amount']))
			excelItems.push(Number(data[i]['gross_income_total_amount']))
			excelItems.push(Number(data[i]['cheque_total_discount']))
			excelItems.push(Number(data[i]['cheque_total_agent_amount']))
			excelItems.push(Number(data[i]['expenses_total_amount']))
			excelItems.push(Number(data[i]['salary_debt']))
			excelItems.push(Number(data[i]['salary_total_paid']))
			excelItems.push(Number(data[i]['salary_balance']))
			excelItems.push(Number(data[i]['cash_total_amount']))
			excelItems.push(Number(data[i]['terminal_total_amount']))
			excelItems.push(Number(data[i]['client_debtor_amount_out']))
			excelItems.push(Number(data[i]['client_debtor_amount_in']))
			excelItems.push(Number(data[i]['cashbox_balance']))
			excelItems.push(Number(data[i]['net_profit']))
			excelHeaders.push(excelItems)
		}

		excelHeaders.push([
			t('total'),
			Number(overall['documents_in_overall']),
			Number(overall['supplier_overall']),
			Number(overall['sales_overall']),
			Number(overall['gross_income_overall']),
			Number(overall['cheque_discount_overall']),
			Number(overall['cheque_agent_amount_overall']),
			Number(overall['expenses_overall']),
			Number(overall['salary_debt_overall']),
			Number(overall['salary_overall']),
			Number(overall['salary_balance_overall']),
			Number(overall['cash_overall']),
			Number(overall['terminal_overall']),
			Number(overall['client_debtor_out_overall']),
			Number(overall['client_debtor_in_overall']),
			Number(overall['cashbox_balance_overall']),
			Number(overall['net_profit_overall']),
		])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('summary_report') + ".xlsx");
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function handleSelectedDate(type, month = true, date) {
		if (month) {
			setFilterData({
				...filterData,
				'start_date': format(startOfMonth(new Date(filterData.start_date)), 'yyyy-MM-dd'),
				'end_date': format(endOfMonth(new Date(filterData.start_date)), 'yyyy-MM-dd'),
				'type': type,
				'random_active_modal': filterData.random_active_modal + 1,
			})
		} else {
			setFilterData({
				...filterData,
				'start_date': date,
				'end_date': date,
				'type': type,
				'random_active_modal': filterData.random_active_modal + 1,
			})
		}
	}

	async function getPoses() {
		var response = await GET('/helper/poses')
		if (httpOk(response)) {
			response = response.data
			setPoses(response)
			if (response.length > 0) {
				setFilterData({
					...filterData,
					'pos_id': response[0]['id'],
					'random': filterData.random + 1,
				})
			}
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		setActiveModal(filterData.type)
	}, [filterData.random_active_modal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('summary_report')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id, 'plan': option.plan })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('date')}</label>
												<div className="calendar-input">
													<DatePicker
														showMonthYearPicker
														showFullMonthYearPicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => exportToExcel()}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
					</div>

					<div className="table-responsive table-fixed mb-3">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>{t('date')}</th>
									<th className="text-end">{t('good_reception')}</th>
									<th className="text-end">{t('paid_supplier')}</th>
									<th className="text-end">{t('sales')}</th>
									<th className="text-end">{t('gross_income')}</th>
									<th className="text-end">{t('discount')}</th>
									<th className="text-end">{t('bonus')}</th>
									<th className="text-end">{t('expense')}</th>
									<th className="text-end">{t('salary')}</th>
									<th className="text-end">{t('paid')}</th>
									<th className="text-end">{t('salary_balance')}</th>
									<th className="text-end">
										<CustomTooltip text={`${t('cash')} = ${t('cash')} - ${t('expense')} - ${t('change2')}`}>
											{t('cash')}
										</CustomTooltip>
									</th>
									<th className="text-end">{t('bank')}</th>
									<th className="text-end">{t('loyalty')}</th>
									<th className="text-end">{t('debt')}</th>
									<th className="text-end">{t('debt_returned')}</th>
									<th className="text-end">
										<CustomTooltip text={`${t('cashbox_balance')} = ${t('sales')} - ${t('discount')} - ${t('bonus')} - ${t('expense')} - ${t('prepaid_expense')} - ${t('bank')} - ${t('debt')} + ${t('debt_returned')}`}>
											{t('cashbox_balance')}
										</CustomTooltip>
									</th>
									<th className="text-end">
										<CustomTooltip text={`${t('net_profit')} = ${t('gross_income')} - ${t('discount')} - ${t('bonus')} - ${t('expense')} - ${t('salary')} - ${t('debt')} + ${t('debt_returned')}`}>
											{t('net_profit')}
										</CustomTooltip>
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{formatDate(item.days)}</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('incomes', false, item.days)}>
											{formatMoney(item.documents_in_total_amount)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('incomes', false, item.days)}>
											{formatMoney(item.supplier_total_amount)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('sales', false, item.days)}>
											{formatMoney(item.sales_total_amount)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('gross_income', false, item.days)}>
											{formatMoney(item.gross_income_total_amount)}
										</td>
										<td className="text-end text-nowrap">
											{formatMoney(item.cheque_total_discount)}
										</td>
										<td className="text-end text-nowrap">{formatMoney(item.cheque_total_agent_amount)}</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('expenses', false, item.days)}>
											{formatMoney(item.expenses_total_amount)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('salary', false, item.days)}>
											{formatMoney(item.salary_debt)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('salary', false, item.days)}>
											{formatMoney(item.salary_total_paid)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('salary', false, item.days)}>
											{formatMoney(item.salary_balance)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('cash', false, item.days)}>
											{formatMoney(item.cash_total_amount)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('bank', false, item.days)}>
											{formatMoney(item.bank_total_amount)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('loyalty', false, item.days)}>
											{formatMoney(item.loyalty_amount_out)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('debt', false, item.days)}>
											{formatMoney(item.debt_total_amount_out)}
										</td>
										<td className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('debt', false, item.days)}>
											{formatMoney(item.debt_total_amount_in)}
										</td>
										<td className="text-end text-nowrap">{formatMoney(item.cashbox_balance)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.net_profit)}</td>
									</tr>
								))}
								<tr>
									<td><b>{t('percentage')}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(overall.overall_documents_in_percentage, 2)}</b>
									</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(100)}</b></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(overall.overall_gross_income_percentage, 2)}</b>
									</td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(overall.overall_discount_percentage, 2)}</b>
									</td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(overall.overall_agent_percentage, 2)}</b>
									</td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(overall.overall_expense_percentage, 2)}</b>
									</td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(overall.overall_salary_percentage, 2)}</b>
									</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">-</td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(overall.overall_net_profit_percentage, 2)}</b>
									</td>
								</tr>
							</tbody>
							<tfoot>
								{data.length > 0 &&
									<tr>
										<th><b>{t('total')}</b></th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('incomes')}>
											<b>{formatMoney(overall.documents_in_overall)}</b>
										</th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('incomes')}>
											<b>{formatMoney(overall.supplier_overall)}</b>
										</th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('sales')}>
											<b>{formatMoney(overall.sales_overall)}</b>
										</th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('gross_income')}>
											<b>{formatMoney(overall.gross_income_overall)}</b>
										</th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.cheque_discount_overall)}</b></th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.cheque_agent_amount_overall)}</b></th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('expenses', true)}>
											<b>{formatMoney(overall.expenses_overall)}</b>
										</th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.salary_debt_overall)}</b></th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.salary_overall)}</b></th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.salary_balance_overall)}</b></th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.cash_overall)}</b></th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('bank')}>
											<b>{formatMoney(overall.bank_overall)}</b>
										</th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('loyalty')}>
											<b>{formatMoney(overall.loyalty_overall)}</b>
										</th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('debt')}>
											<b>{formatMoney(overall.client_debtor_out_overall)}</b>
										</th>
										<th className="text-end text-nowrap hover"
											onClick={() => handleSelectedDate('debt')}>
											<b>{formatMoney(overall.client_debtor_in_overall)}</b>
										</th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.cashbox_balance_overall)}</b></th>
										<th className="text-end text-nowrap"><b>{formatMoney(overall.net_profit_overall)}</b></th>
									</tr>
								}
							</tfoot>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-between">
						<div className="flex-column">
							<h5 className="d-flex justify-content-between gap-3">
								<strong>{t('cheques')}:</strong>
								<span>{formatMoney(overall.total_cheques)}</span>
							</h5>
							<h5 className="d-flex justify-content-between gap-3">
								<strong>{t('guests')}:</strong>
								<span>{formatMoney(overall.total_people_number)}</span>
							</h5>
						</div>
					</div>
				</div>
			</div>

			{activeModal === 'expenses' &&
				<SummaryExpense
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummaryExpense>
			}
			{activeModal === 'incomes' &&
				<SummaryIncomes
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummaryIncomes>
			}
			{activeModal === 'cash' &&
				<SummaryCash
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummaryCash>
			}
			{activeModal === 'bank' &&
				<SummaryBank
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummaryBank>
			}
			{activeModal === 'debt' &&
				<SummaryDebt
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummaryDebt>
			}
			{activeModal === 'salary' &&
				<SummarySalary
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummarySalary>
			}
			{activeModal === 'loyalty' &&
				<SummaryLoyalty
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummaryLoyalty>
			}
			{activeModal === 'safe' &&
				<SammarySafe
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SammarySafe>
			}
			{activeModal === 'sales' &&
				<SummarySales
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummarySales>
			}
			{activeModal === 'gross_income' &&
				<SummaryGrossIncome
					setActiveModal={setActiveModal}
					filterData={filterData}>
				</SummaryGrossIncome>
			}
		</>
	)
}

export default Summary