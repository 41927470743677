import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'
import Select from 'react-select'

import { httpOk, GET } from 'helpers/api'

function Settings({ data, setData, handleSettingChange }) {
	const { t } = useTranslation()

	const smsServices = [
		{ 'id': 1, 'name': 'SMS FLY' },
		{ 'id': 2, 'name': 'PLAYMOBILE' },
	]
	const [poses, setPoses] = useState([])

	async function getPosSettings() {
		const response = await GET('/pos-settings', {}, { loader: false })
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	function handleDeliveryStatusChange(e) {
		var dataCopy = { ...data }

		if (!dataCopy.delivery_statuses) {
			dataCopy.delivery_statuses = {}
		}

		dataCopy.delivery_statuses[e.target.name] = e.target.value
		setData(dataCopy)
	}

	useEffect(() => {
		getPosSettings()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<form autoComplete="off">
				<div className="row">
					<div className="col-md-6">
						<div className="d-flex justify-content-between mb-2">
							<div className="fw-500 vertical-center">
								СМС Сервис
							</div>
							<div className="w-50">
								<input type="text" className="form-control" name="max_delivery_distance"
									value={data?.settings.max_delivery_distance ?? ''}
									onChange={(e) => handleSettingChange(e, 'text')} />
							</div>
						</div>

						<div className="d-flex justify-content-between mb-2">
							<div className="fw-500 vertical-center">
								СМС Сервис
							</div>
							<div className="w-50">
								<Select
									options={smsServices}
									value={smsServices.find(option => option.id === data?.settings.sms_service_id) || ''}
									onChange={(option) => handleSettingChange(option, 'select', 'sms_service_id')}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						{data?.settings.sms_service_id === 1 &&
							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									Ключ СМС Сервиса SMS FLY
								</div>
								<div className="d-flex w-50">
									<input type="password" className="form-control" name="sms_api_key"
										value={data?.settings.sms_api_key ?? ''}
										onChange={(e) => handleSettingChange(e, 'text')} />
								</div>
							</div>
						}
						{data?.settings.sms_service_id === 2 &&
							<>
								<div className="d-flex justify-content-between mb-2">
									<div className="fw-500 vertical-center">
										PLAYMOBILE {t('login')}
									</div>
									<div className="d-flex w-50">
										<input type="text" className="form-control" name="playmobile_login"
											value={data?.settings.playmobile_login ?? ''}
											onChange={(e) => handleSettingChange(e, 'text')} />
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<div className="fw-500 vertical-center">
										PLAYMOBILE {t('password')}
									</div>
									<div className="d-flex w-50">
										<input type="password" className="form-control" name="playmobile_password"
											value={data?.settings.playmobile_password ?? ''}
											onChange={(e) => handleSettingChange(e, 'text')} />
									</div>
								</div>
							</>
						}
					</div>

					<div className="col-4">
						<div className="table-responsive">
							<table className="table table-bordered mb-0">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '90%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }}></th>
									</tr>
								</thead>
								<tbody>
									{poses.map((item, index) => (
										<tr key={index}>
											<td style={{ 'width': '33px' }} className="text-center">{index + 1}</td>
											<td>{item.name}</td>
											<td className="d-flex justify-content-end">
												<div className="form-check form-switch form-switch-sm">
													<input type="checkbox" className="form-check-input"
														checked={item.bool_telegram_bot ? true : false}
														onChange={() => { }} />
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<h5>Время обработки каждого статуса доставки</h5>
						<hr />
						<div className="d-flex justify-content-between mb-2">
							<div className="fw-500 vertical-center">
								2. Подтверждение кассиром (Заказ поступл на кухню)
							</div>
							<div>
								<MaskedInput
									name="status_1"
									mask={[/[0-9]/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/,]}
									className="form-control"
									placeholder="00:00:00"
									guide={true}
									onChange={handleDeliveryStatusChange}
									value={data?.settings.delivery_statuses?.status_1 ?? ""}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between mb-2">
							<div className="fw-500 vertical-center">
								3. Готово к выдаче курьеру (Блюда приготовлены)
							</div>
							<div>
								<MaskedInput
									name="status_6"
									mask={[/[0-9]/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/,]}
									className="form-control"
									placeholder="00:00:00"
									guide={true}
									onChange={handleDeliveryStatusChange}
									value={data?.settings.delivery_statuses?.status_6 ?? ""}
								/>
							</div>
						</div>
						<div className="d-flex justify-content-between mb-2">
							<div className="fw-500 vertical-center">
								4. Курьер в пути
							</div>
							<div>
								<MaskedInput
									name="status_8"
									mask={[/[0-9]/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/,]}
									className="form-control"
									placeholder="00:00:00"
									guide={true}
									onChange={handleDeliveryStatusChange}
									value={data?.settings.delivery_statuses?.status_8 ?? ""}
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default Settings