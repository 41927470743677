import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, formatMoney, regexPhoneNumber, returnSign } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [modalWriteOff, setModalWriteOff] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({ pos_id: reduxAccount.pos_id })
	const [pagination, setPagination] = useState({
		url: '/report-clients',
		response: null
	})
	const [selectedItem, setSelectedItem] = useState({})

	async function searchWithFilter() {
		var filterUrl = "/report-clients"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-clients${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function payment(e) {
		e.preventDefault();

		var response = await POST('/clients-payment', selectedItem, { loader: true })

		if (httpOk(response)) {
			toggleModalPayment(false)
			searchWithFilter()
		}
	}

	async function writeOff(e) {
		e.preventDefault();
		var response = await POST('/clients/writeoff-debt', selectedItem, { loader: true })

		if (httpOk(response)) {
			toggleModalWriteOff(false)
			searchWithFilter()
		}
	}

	function toggleModalPayment(bool = false, item = null) {
		var itemCopy = { ...item }

		if (bool) {
			itemCopy.created_at = new Date()
			setSelectedItem(itemCopy)
		} else {
			itemCopy.amount = ''
			itemCopy.note = ""
			itemCopy.created_at = new Date()
			setSelectedItem(itemCopy)
		}

		setModalPayment(bool)
	}

	function toggleModalWriteOff(bool = false, item = null) {
		var itemCopy = { ...item }

		if (bool) {
			itemCopy.amount = item.balance
			itemCopy.note = ""
		}

		setSelectedItem(itemCopy)
		setModalWriteOff(bool)
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('clients')} [{pagination?.response?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter(false)}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div className="d-flex gap-2">
							<div className="vertical-center">
								<h4><b>{t('amount')}</b>:</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`${data.total_amount >= 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.total_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('client')}</th>
									<th style={{ 'width': '10%' }}>{t("phone")}</th>
									<th style={{ 'width': '60%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t("debt")}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">
											<Link className="text-primary cursor table-first-column-clickable"
												to={`/report-clients-debts/${item.client_id}`}>
												{item.first_name}
											</Link>
										</td>
										<td className="text-nowrap">{regexPhoneNumber(item.phone1)}</td>
										<td></td>
										<td className="text-end text-nowrap">
											<div className={item.balance >= 0 ? 'text-primary' : 'text-danger'}>
												<span>{formatMoney(item.balance)}</span>
											</div>
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item d-none"
															onClick={() => toggleModalPayment(true, item)}>
															<i className="uil uil-transaction"></i>
															{t('payment')}
														</div>
														<div className="table-dropdown-item text-danger"
															onClick={() => toggleModalWriteOff(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('write_off')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}

					{/* MODAL PAYMENT */}
					<Modal show={modalPayment} animation={false} centered
						dialogClassName="update-modal-width" backdrop="static"
						onHide={() => toggleModalPayment(false)}>
						<Modal.Header closeButton>
							<Modal.Title>{t('write_off_debt')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form onSubmit={payment} autoComplete="off">
								<div className="form-group">
									<label>{t('amount')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="amount_in" autoFocus
										value={selectedItem.amount_in ? formatMoney(selectedItem.amount_in) : ''}
										disabled />
								</div>
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
								</div>
								<div className="d-flex gap-2 w-100 mt-3">
									<button type="button" className="btn btn-outline-warning w-100"
										onClick={() => toggleModalPayment(false)} tabIndex="-1">{t('cancel')}</button>
									<button type="submit" className="btn btn-primary w-100">
										{t('save')}
									</button>
								</div>
							</form>
						</Modal.Body>
					</Modal>
					{/* MODAL PAYMENT */}

					{/* MODAL WRITE OFF */}
					<Modal show={modalWriteOff} animation={false} centered
						dialogClassName="update-modal-width" backdrop="static"
						onHide={() => toggleModalWriteOff(false)}>
						<Modal.Header closeButton>
							<Modal.Title>{t('write_off_debt')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form onSubmit={writeOff} autoComplete="off">
								<div className="form-group">
									<label>{t('amount')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="amount" autoFocus
										value={selectedItem.amount ? formatMoney(selectedItem.amount) : ''}
										disabled />
								</div>
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
								</div>
								<div className="d-flex gap-2 w-100 mt-3">
									<button type="button" className="btn btn-outline-warning w-100"
										onClick={() => toggleModalWriteOff(false)} tabIndex="-1">
										{t('cancel')}
									</button>
									<button type="submit" className="btn btn-primary w-100">
										{t('save')}
									</button>
								</div>
							</form>
						</Modal.Body>
					</Modal>
					{/* MODAL WRITE OFF */}
				</div>
			</div>
		</>
	)
}

export default Index
