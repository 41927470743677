import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap'
import Select from 'react-select'

import ReactSelect from 'pages/parts/ReactSelect'
import DocumentTemplate from 'pages/parts/DocumentTemplate'
import { DELETE, GET, POST, httpOk } from 'helpers/api'
import { formatMoney, returnSign } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)

	const [data, setData] = useState({
		elements: [],
		pos_id: null,
		note: '',
		avg_sales: 10,
		total_amount: 0,
		total_quantity: 0,
	})
	const [addQuantity, setAddQuantity] = useState(1)
	const [poses, setPoses] = useState([])
	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [products, setProducts] = useState([])
	const [modalTemplate, setModalTemplate] = useState({ 'bool': false, 'templates': [] })
	const [modalTemplateCRUD, setModalTemplateCRUD] = useState({ 'bool': false, 'name': "", 'items': [] })

	async function createUpdate(e) {
		e.preventDefault();
		var dataCopy = { ...data }
		dataCopy.error = false

		if (dataCopy.elements.length === 0) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.elements.length; i++) {
			if (!dataCopy.elements[i]['quantity']) {
				dataCopy.error = true
			}

			if (Number(dataCopy.elements[i]['quantity']) > Number(dataCopy.elements[i]['wms_balance'])) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.elements.map(function (item) { return item.element_id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		const response = await POST('/documents-orders', dataCopy, { loader: true })

		if (httpOk(response)) {
			navigate('/documents-orders')
		}
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.elements.push({
				'quantity': '',
				'element_id': ''
			})
		}

		calculate(dataCopy)
		setTimeout(() => {
			const byId = document.getElementById(dataCopy.elements.length)
			byId?.select()
		}, 200);
	}

	function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.elements.splice(index, 1)

		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.elements[index][e.target.name] = e.target.value

		let value = e.target.value;
		value = value.replace(/,/g, '.')
		dataCopy.elements[index][e.target.name] = value.replace(/[^\d.]/g, '')

		calculate(dataCopy)
	}

	function calculate(dataCopy) {
		dataCopy.overall_amount = 0
		dataCopy.overall_quantity = 0

		for (let i = 0; i < dataCopy.elements.length; i++) {
			if (dataCopy.elements[i]['quantity']) {
				dataCopy.overall_amount += Number(dataCopy.elements[i]['quantity']) * Number(dataCopy.elements[i]['price'])
				dataCopy.overall_quantity += Number(dataCopy.elements[i]['quantity'])
			}
		}

		setData(dataCopy)
	}

	async function toggleModalTemplate(bool) {
		if (bool) {
			const response = await GET(`/documents-orders-templates?wms_id=${data.wms_id}`, {}, { loader: true })
			if (httpOk(response)) {
				setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': response.data })
			}
		} else {
			setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': [] })
		}
	}

	async function toggleModalTemplateCRUD(bool) {
		if (bool) {
			const response = await GET(`/documents-orders-products`, {}, { loader: true })
			if (httpOk(response)) {
				setProducts(response.data)
				setModalTemplateCRUD({ ...modalTemplateCRUD, 'bool': bool, 'name': "", 'products': [] })
			}
		} else {
			setModalTemplateCRUD({ ...modalTemplateCRUD, 'bool': bool, 'name': "", 'products': [] })
		}
	}

	function handleSelectTemplate(item) {
		setData({ ...data, elements: item.elements })
		toggleModalTemplate(false)
	}

	async function deleteTemplate(item) {
		await DELETE(`/documents-orders-templates/${item.document_order_id}`, {}, { loader: true })
		toggleModalTemplate(false)
	}

	function handleSelectPos(option) {
		var dataCopy = { ...data }

		dataCopy.to_pos_id = option.id
		dataCopy.to_login = option.login

		setData(dataCopy)
		getElements(dataCopy)
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }

		dataCopy.elements[index]['element_id'] = option.id
		dataCopy.elements[index]['avg_sales'] = option.avg_sales
		dataCopy.elements[index]['name'] = option.name
		dataCopy.elements[index]['balance'] = option.balance
		dataCopy.elements[index]['unit_name'] = option.unit_name
		if (option.price) {
			dataCopy.elements[index]['price'] = Number(option.price).toFixed(0)
		}

		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function getElements(dataCopy, refresh = false) {
		var urlParams = ""
		if (dataCopy.wms_id)
			urlParams += returnSign(urlParams) + 'wms_id=' + dataCopy.wms_id
		if (dataCopy.to_login)
			urlParams += returnSign(urlParams) + 'to_login=' + dataCopy.to_login
		if (dataCopy.to_pos_id)
			urlParams += returnSign(urlParams) + 'to_pos_id=' + dataCopy.to_pos_id
		if (dataCopy.avg_sales)
			urlParams += returnSign(urlParams) + 'avg_sales=' + dataCopy.avg_sales

		const response = await GET(`/documents-orders-elements${urlParams}`, {}, { loader: true })

		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}

		if (refresh) {
			for (let i = 0; i < data?.elements?.length; i++) {
				for (let j = 0; j < response.data.length; j++) {
					if (data.elements[i]['element_id'] === response.data[j]['id']) {
						data.elements[i]['avg_sales'] = response.data[j]['avg_sales'];
					}
				}
			}
		}
	}

	async function getPoses() {
		var response = await GET('/helper/access-poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)

		setData({
			...data,
			'pos_id': reduxAccount.pos_id,
			'wms_id': reduxAccount.wms_id
		})
	}

	async function getData() {
		const response = await GET(`/documents-orders/${id}`, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			getElements(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		if (id) {
			getData()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('order')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
				<div>
					<DropdownButton title={t('action')}>
						<Dropdown.Item onClick={() => toggleModalTemplate(true)}>
							{t('templates')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => toggleModalTemplateCRUD(true)}>
							Создать шаблон
						</Dropdown.Item>
					</DropdownButton>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="d-flex flex-wrap gap-2">
								<div className="form-group" style={{ 'width': '200px' }}>
									<label>{t('external_supplier')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={id}
										options={poses}
										value={poses.find(option => option.id === data.to_pos_id)}
										onChange={(option) => handleSelectPos(option)}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group" style={{ 'width': '200px' }}>
									<label>{t('average_sale_days')}</label>
									<div className="position-relative">
										<input type="text" className="form-control"
											name="avg_sales"
											value={data.avg_sales ?? ''}
											onChange={(e) => setData({ ...data, 'avg_sales': e.target.value.replace(/[^0-9]/g, '') })} />
										<i className="uil uil-sync"
											onClick={() => getElements(data, true)}
											style={{
												'position': 'absolute',
												'top': '7px',
												'right': '5px',
												'fontSize': '16px',
												'zIndex': '999',
												'cursor': 'pointer'
											}}
											title={t('refresh')}>
										</i>
									</div>
								</div>
								<div className="form-group" style={{ 'width': '200px' }}>
									<label>{t('note')}</label>
									<input type="text" className="form-control"
										name="note"
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '30%' }}>{t('name2')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
										<th style={{ 'width': '10%' }}>{t('unit')}</th>
										<th style={{ 'width': '20%' }}></th>
										<th style={{ 'width': '10%' }} className="text-end"
											title={`Средная продажа за ${data.avg_sales} дней`}>
											{t('sales')}
										</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
										<th style={{ 'minWidth': '33px' }}></th>
									</tr>
								</thead>
								<tbody>
									{data?.elements?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>
												<ReactSelect
													search={handleSelectSearch}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={elements}
													onClose={() => closeReactSelect()}
													selectItem={(option) => handleSelectElement(option, index)}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDown(e)
														}
													}}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{elements.map((selectItem, selectIndex) => (
																	<tr
																		id={selectItem.id}
																		className={`cursor ${((selectItem.selected || selectItem.id === item.element_id) && 'selected')}`}
																		tabIndex={-1}
																		key={selectIndex}
																		onClick={() => handleSelectElement(selectItem, index)}
																		onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																		<td>{selectItem.name}</td>
																		<td>{selectItem.product_type_name}</td>
																		<td className="text-end text-nowrap">{formatMoney(selectItem.price)}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											</td>
											<td className="text-end text-nowrap vertical-middle">
												{formatMoney(item.balance)}
											</td>
											<td className="vertical-middle">
												{item.unit_name}
											</td>
											<td></td>
											<td className="text-end text-nowrap vertical-middle">
												{formatMoney(item.avg_sales, 2)}
											</td>
											<td className="td-input" style={!item.quantity ? { 'borderBottom': '2px solid red' } : {}}>
												<input
													type="text"
													name="quantity"
													className="form-control text-end"
													placeholder="0"
													value={item.quantity ?? ''}
													onChange={(e) => handleInputChange(e, index)} />
											</td>
											<td className="vertical-middle text-end text-nowrap">
												{formatMoney(item.quantity * (item.price ?? 0))}
											</td>
											<td className="vertical-middle">
												<div className="d-flex justify-content-end">
													<div className="table-action-button table-action-danger-button"
														onClick={() => deleteItem(index)}>
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												placeholder="xxx"
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={!data.to_pos_id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity)
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall_quantity)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall_amount)}</b></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>

						<div className="btn-wrapper gap-2">
							<Link className="btn btn-warning btn-rounded btn-wide" to="/documents-orders" tabIndex="-1">
								{t('cancel')}
							</Link>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								onClick={createUpdate}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form>

			{/* MODAL TEMPLATE */}
			<Modal show={modalTemplate.bool} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalTemplate(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('templates')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '10px' }}>#</th>
									<th>{t('name2')}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{modalTemplate?.templates?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}</td>
										<td onClick={() => handleSelectTemplate(item)}>
											{item.name}
										</td>
										<td>
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteTemplate(item)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TEMPLATE */}

			{/* MODAL TEMPLATE */}
			{modalTemplateCRUD.bool &&
				<DocumentTemplate
					products={products}
					modalTemplateCRUD={modalTemplateCRUD}
					setModalTemplateCRUD={setModalTemplateCRUD}
					toggleModalTemplateCRUD={toggleModalTemplateCRUD}>
				</DocumentTemplate>
			}
			{/* MODAL TEMPLATE */}
		</>
	)
}

export default CreateUpdate