import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { findKeyFromArrayByValue, formatDate, formatMoney, formatMoneyInput, returnSign } from 'helpers/helpers'
import { GET, POST, PUT, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const statuses = [
		{ 'id': 1, 'name': "Активный" },
		{ 'id': -1, 'name': "Отключен" },
		{ 'id': -2, 'name': "Заморожен" },
		{ 'id': -3, 'name': "Удален" },
	]

	const paymentStatuses = [
		{ 'id': 1, 'name': "Оплата" },
		{ 'id': -1, 'name': "Минус баланс" },
		{ 'id': -2, 'name': "Списание" },
		{ 'id': -3, 'name': "Перевод" },
	]

	const posTypes = [
		{ 'id': 1, 'name': "Торговая точка" },
		{ 'id': 2, 'name': "Головной офис" },
	]

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [users, setUsers] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [payments, setPayments] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [partners, setPartners] = useState([])
	const [modalUpdate, setModalUpdate] = useState(false)
	const [modalPayment, setModalPayment] = useState(false)
	const [modalInvoice, setModalInvoice] = useState(false)
	const [modalInfo, setModalInfo] = useState(false)
	const [modalStatus, setModalStatus] = useState(false)
	const [modalCashiers, setModalCashiers] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({ status: 1 })
	const [pagination, setPagination] = useState({
		url: '/admin/pos',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/admin/pos"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/admin/pos${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();

		if (!selectedItem.id) {
			await POST('/admin/pos', selectedItem, { loader: true })
		} else {
			await PUT('/admin/pos', selectedItem, { loader: true })
		}

		setSelectedItem({})
		setModalUpdate(false)
		searchWithFilter()
	}

	async function payment(e) {
		e.preventDefault();

		var response = await POST('/admin/pos-payment', selectedItem, { loader: true })
		if (httpOk(response)) {
			setSelectedItem({})
			setModalPayment(false)
			searchWithFilter()
		}
	}

	async function createInvoice(e) {
		e.preventDefault();

		var sendData = {}

		sendData.id = selectedItem.id
		sendData.pos_payment_type_id = 1
		sendData.amount = selectedItem.amount
		sendData.phone = `998${selectedItem.invoice_phone}`

		const response = await POST('/payment/pos-invoice', sendData)
		if (httpOk(response)) setModalInvoice(false)
	}

	function toggleModalUpdate(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem(selectedItemCopy)
		setModalUpdate(true)
	}

	function toggleModalPayment(item) {
		if (!poses.length) getPoses()

		var selectedItemCopy = { ...item }
		selectedItemCopy.payment = ""
		selectedItemCopy.note = ""
		selectedItemCopy.status = 1
		selectedItemCopy.to_pos_id = ''
		selectedItemCopy.amount = item.tariff
		setSelectedItem(selectedItemCopy)
		setModalPayment(true)
	}

	function toggleModalInvoice(item) {

		var selectedItemCopy = { ...item }
		if (item.balance <= 0) {
			selectedItemCopy.amount = Math.abs(item.balance)
		} else {
			selectedItemCopy.amount = item.tariff
		}
		selectedItemCopy.invoice_phone = ""
		
		setSelectedItem(selectedItemCopy)
		setModalInvoice(true)
	}

	async function toggleModalInfo(item) {
		const response = await GET('/admin/pos-payment-detail', {
			'login': item.login,
			'id': item.id,

		}, { loader: true })

		if (httpOk(response)) {
			setPayments(response.data)
			setModalInfo(true)
		}
	}

	async function toggleModalCashiers(item) {
		const response = await GET('/admin/pos-cashiers', {
			'id': item.id,
		}, { loader: true })

		if (httpOk(response)) {
			setCashiers(response.data)
			setModalCashiers(true)
		}
	}

	async function handleChangeStatus(e) {
		e.preventDefault()

		await PUT('/admin/pos/change-status', selectedItem, { loader: true })
		searchWithFilter()
		toggleModalStatus(false)
	}

	async function toggleModalStatus(bool, item = {}) {
		if (bool) {
			setSelectedItem(item)
			setModalStatus(true)
		} else {
			setModalStatus(false)
		}
	}

	async function toggleModalCreateUpdate(bool, item = {}) {
		if (!users.length) getUsers()
		if (!item.id) item.type = 1
		setSelectedItem(item)
		setModalUpdate(bool)
	}

	function resetFilter() {
		setFilterData({
			status: null,
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (filterData.status === null) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function returnClassStatus(status) {
		if (status === 1) {
			return "bg-primary"
		}
		if (status === -1) {
			return "bg-danger"
		}
		if (status === -2) {
			return "bg-secondary"
		}
		if (status === -3) {
			return "bg-danger"
		}
	}

	async function getUsers() {
		const response = await GET('/helper/users')
		if (httpOk(response)) setUsers(response.data)
	}

	async function getPoses() {
		const response = await GET('/admin/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getPartners() {
		const response = await GET('/admin/helper/partners')
		if (httpOk(response)) setPartners(response.data)
	}

	useEffect(() => {
		getPartners()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="vertical-center">
					<h4>
						{t('poses')} [{data.total}]
					</h4>
				</div>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModalCreateUpdate(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="d-flex gap-2">
								<div className="vertical-center">
									<span className="bg-primary bg-border text-white">
										<span>{t('active')}: {data?.status?.total_active}</span>
									</span>
								</div>
								<div className="vertical-center">
									<span className="bg-secondary bg-border text-white">
										<span>{t('disconnected')}: {data?.status?.total_not_active}</span>
									</span>
								</div>
								<div className="vertical-center">
									<span className="bg-danger bg-border text-white">
										<span>{t('deleted')}: {data?.status?.total_deleted}</span>
									</span>
								</div>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'minWidth': '33px' }} className="text-center">ID</th>
									<th style={{ 'width': '10%' }}>{t('login')}</th>
									<th style={{ 'width': '50%' }}>{t('pos')}</th>
									<th style={{ 'minWidth': '105px' }}>{t('phone')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('tariff')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('balance')}</th>
									<th style={{ 'minWidth': '88px' }} className="text-center">{t('date')}</th>
									<th style={{ 'minWidth': '88px' }} className="text-center">{t('credit')}</th>
									<th style={{ 'width': '10%' }} className="text-center td-ellipsis"
										title={t('last_cheque')}>
										{t('last_cheque')}
									</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
									<th style={{ 'minWidth': '33px' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="text-center"><b>{item.id}</b></td>
										<td>{item.login}</td>
										<td className="td-ellipsis" title={item.id}>{item.name}</td>
										<td>{item.phone}</td>
										<td className="text-end">{formatMoney(item.tariff)}</td>
										<td className="text-end">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td className="text-center">{formatDate(item.credit_end_date)}</td>
										<td className="text-center">{formatDate(item.last_cheque_date)}</td>
										<td className={`text-center text-white cursor ${returnClassStatus(item.status)}`}
											onClick={() => toggleModalStatus(true, item)}>
											{item.status === 1 &&
												<span>{t('activated')}</span>
											}
											{item.status === -1 &&
												<span>{t('disconnected')}</span>
											}
											{item.status === -2 &&
												<span>{t('frozen')}</span>
											}
											{item.status === -3 &&
												<span>{t('deleted')}</span>
											}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModalUpdate(item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														<div className="table-dropdown-item"
															onClick={() => toggleModalPayment(item)}>
															<i className="uil-edit-alt"></i>
															Оплата
														</div>
														<div className="table-dropdown-item"
															onClick={() => toggleModalInfo(item)}>
															<i className="uil-info-circle"></i>
															История оплат
														</div>
														<div className="table-dropdown-item"
															onClick={() => toggleModalCashiers(item)}>
															<i className="uil-info-circle"></i>
															Кассиры
														</div>
														<div className="table-dropdown-item"
															onClick={() => toggleModalInvoice(item)}>
															<i className="uil-arrow-right"></i>
															Выставить счет
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div >

			{/* MODAL UPDATE*/}
			<Modal Modal show={modalUpdate} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalUpdate(false)
				}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>Создать</span>
							:
							<span>Редактировать</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						{!selectedItem.id &&
							<div className="form-group">
								<label>Логин</label>
								<Select
									options={users}
									value={users.find(option => option.login === selectedItem.login)}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'login': option.login })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.login}
									getOptionValue={(option) => option.login}
								/>
							</div>
						}
						{!selectedItem.id &&
							<div className="form-group">
								<label>Тип</label>
								<Select
									options={posTypes}
									value={posTypes.find(option => option.id === selectedItem.type)}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'type': option.id })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						}
						{selectedItem.id &&
							<div className="form-group">
								<label>Партнер</label>
								<Select
									isClearable
									options={partners}
									value={partners.find(option => option.login === selectedItem.partner_login) || ''}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'partner_login': option ? option.login : null })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.login}
								/>
							</div>
						}
						<div className="form-group">
							<label>Название</label>
							<input type="text" className="form-control" name="name" value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						{!selectedItem.id &&
							<div className="form-group">
								<label>Тариф</label>
								<input type="text" className="form-control" name="tariff"
									value={formatMoneyInput(selectedItem.tariff) ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'tariff': e.target.value.replace(/[^0-9.]/g, '') })} />
							</div>
						}
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => setModalUpdate(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal >
			{/* MODAL UPDATE*/}

			{/* MODAL PAYMENT*/}
			<Modal show={modalPayment} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalPayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Оплата</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => payment(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('type')}</label>
							<Select
								isClearable
								options={paymentStatuses}
								value={paymentStatuses.find(option => option.id === selectedItem.status) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'status': option ? option.id : null })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{selectedItem.status === -3 &&
							<div className="form-group">
								<label>Перевод на точку</label>
								<Select
									isClearable
									options={poses}
									value={poses.find(option => option.id === selectedItem.to_pos_id) || ''}
									onChange={(option) => setSelectedItem({ ...selectedItem, 'to_pos_id': option ? option.id : null })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						}
						<div className="form-group">
							<label>Сумма</label>
							<input type="text" className="form-control" name="amount"
								value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.-]/g, '') })} />
						</div>
						<div className="form-group">
							<label>Примечание</label>
							<input type="text" className="form-control" name="note" value={selectedItem.note}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => setSelectedItem(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT*/}

			{/* MODAL INVOICE*/}
			<Modal show={modalInvoice} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalInvoice(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Выставить счет</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createInvoice(e)} autoComplete="off">
						{console.log(selectedItem)}
						<div className="form-group">
							<label>Сумма</label>
							<input type="text" className="form-control" name="amount"
								value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9]/g, '') })} />
						</div>
						<div className="input-group mb-2">
							<span className="input-group-text">998</span>
							<input type="number" className="form-control"
								name="invoice_phone"
								placeholder={t('phone')}
								value={selectedItem.invoice_phone ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'invoice_phone': e.target.value.replace(/[^0-9]/g, '') })}
							/>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => setSelectedItem(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL INVOICE*/}

			{/* MODAL CHANGE STATUS*/}
			<Modal show={modalStatus} animation={false} centered size="sm"
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalStatus(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Статус</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => handleChangeStatus(e)} autoComplete="off">
						<div className="d-flex flex-column gap-3">
							<div className="form-check">
								<input id="status1" className="form-check-input" type="radio" name="status"
									checked={selectedItem.status === 1 ? true : false}
									onChange={(e) => {
										if (e.target.checked) {
											setSelectedItem({ ...selectedItem, 'status': 1 })
										}
									}}
								/>
								<label className="form-check-label" htmlFor="status1">
									Активировать
								</label>
							</div>
							<div className="form-check">
								<input id="status-1" className="form-check-input" type="radio" name="status"
									checked={selectedItem.status === -1 ? true : false}
									onChange={(e) => {
										if (e.target.checked) {
											setSelectedItem({ ...selectedItem, 'status': -1 })
										}
									}}
								/>
								<label className="form-check-label" htmlFor="status-1">
									Заблокирован за неуплату
								</label>
							</div>
							<div className="form-check">
								<input id="status-2" className="form-check-input" type="radio" name="status"
									checked={selectedItem.status === -2 ? true : false}
									onChange={(e) => {
										if (e.target.checked) {
											setSelectedItem({ ...selectedItem, 'status': -2 })
										}
									}}
								/>
								<label className="form-check-label" htmlFor="status-2">
									Замороженный
								</label>
							</div>
							<div className="form-check">
								<input id="status-3" className="form-check-input" type="radio" name="status"
									checked={selectedItem.status === -3 ? true : false}
									onChange={(e) => {
										if (e.target.checked) {
											setSelectedItem({ ...selectedItem, 'status': -3 })
										}
									}}
								/>
								<label className="form-check-label" htmlFor="status-3">
									Удален
								</label>
							</div>
						</div>

						<div className="d-flex w-100 mt-3">
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL CHANGE STATUS*/}

			{/* MODAL PAYMENT HISTORY*/}
			<Modal show={modalInfo} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalInfo(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						История
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th className="text-nowrap">{t('date')}</th>
									<th className="text-nowrap">{t('note')}</th>
									<th className="text-nowrap text-center">{t('status')}</th>
									<th className="text-nowrap text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{payments?.map((item, index) => (
									<tr key={index}>
										<td>{formatDate(item.created_at, 'dd.MM.yyyy HH:mm')}</td>
										<td className="td-ellipsis" title={item.note}>{item.note}</td>
										<td className="text-center">
											{item.status === 1 &&
												<span className="text-success">Успешно</span>
											}
											{item.status === -2 &&
												<span className="text-danger">Списание</span>
											}
											{item.status === -3 &&
												<span className="text-danger">Перевод</span>
											}
										</td>
										<td className="text-nowrap text-end">{formatMoney(item.amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT HISTORY*/}

			{/* MODAL CASHIERS*/}
			<Modal show={modalCashiers} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalCashiers(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						Кассиры
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th className="text-nowrap text-center">{t('login')}</th>
									<th className="text-nowrap text-center">{t('name')}</th>
								</tr>
							</thead>
							<tbody>
								{cashiers?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.login}</td>
										<td className="text-center">{item.first_name}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CASHIERS*/}
		</>
	)
}

export default Index