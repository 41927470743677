import React, { Fragment, useEffect, useState, useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { useReactToPrint } from 'react-to-print'
import { Modal, Dropdown } from 'react-bootstrap'

import Rightbar from 'pages/main/cheques/Rightbar'
import CustomTooltip from "pages/parts/CustomTooltip"

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { FILE_D, GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const printRef = useRef();

	const statuses = [
		{ 'id': 10, 'name': t('success') },
		{ 'id': -1, 'name': t('deleted') },
		{ 'id': -2, 'name': t('changed') },
	]

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [cheques, setCheques] = useState([]);
	const [modalCheques, setModalCheques] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: [],
		status: 10,
	})

	async function searchWithFilter(filterDataCopy) {
		//var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/product-sales"
		var urlParams = ""

		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setDropdownIsOpen(false)
			setData(response.data)
			setFilterData(filterDataCopy)
		}
	}

	async function toggleModalCheques(bool = false, item = {}) {
		if (bool) {
			var filterDataCopy = { ...filterData }
			var filterUrl = "/chain/product-sales-cheques"
			var urlParams = ""

			if (item.id)
				urlParams += returnSign(urlParams) + 'pos_id=' + item.id
			if (item.product_id)
				urlParams += returnSign(urlParams) + 'product_id=' + item.product_id
			if (filterDataCopy.status)
				urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
			if (filterDataCopy.start_date)
				urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
			if (filterDataCopy.end_date)
				urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

			filterUrl = filterUrl + urlParams

			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setCheques(response.data)
				setModalCheques(bool)
			}
		} else {
			setCheques([])
			setModalCheques(bool)
		}
	}

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...cheques]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}
		if (boolean) {
			const response = await GET(`/cheque-params/${item.id}`, {}, { loader: true })
			if (httpOk(response)) {
				dataCopy[index]['products'] = response.data.products
				dataCopy[index]['payments'] = response.data.payments
				dataCopy[index]['loyalty'] = response.data.loyalty
				dataCopy[index]['showSidebar'] = boolean

				setSelectedItem(dataCopy[index])
				setShowRightBar(boolean)
			}

		} else {
			setShowRightBar(boolean)
			setSelectedItem({})
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	async function downloadExcelFile() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/product-sales"
		var urlParams = ""

		urlParams += "?excel=true"
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		var response = await FILE_D(filterUrl)
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `chain_sales.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	async function getPoses() {
		const response = await GET('/helper/poses')

		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div style={{ 'minWidth': '200px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 160px)', 'overflow': 'auto' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column" style={{ 'height': 'calc(100vh - 267px)', 'overflow': 'auto' }}>
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)} />
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
							<Dropdown>
								<Dropdown.Toggle variant="outline-primary w-100">
									{t('action')}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<div className="table-dropdown-item"
										onClick={handlePrint}>
										<i className="uil-print"></i>
										{t('print')}
									</div>
									<div className="table-dropdown-item"
										onClick={() => downloadExcelFile()}>
										<i className="uil uil-file-download-alt"></i>
										EXCEL
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 w-100">
									{t('filter')}
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('status')}</label>
											<Select
												isClearable
												options={statuses}
												value={statuses.find(option => option.id === filterData.status) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter(filterData)}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>

				<div className="w-100">
					<div className="card">
						<div className="card-body">
							<div className="table-responsive table-fixed" style={{ 'height': 'calc(100vh - 105px)', 'overflow': 'auto' }}>
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '50%' }}>{t('name2')}</th>
											<th style={{ 'width': '5%' }} className="text-center">
												{t('unit')}
											</th>
											{data?.poses?.map((item, index) => (
												<th key={index} style={{ 'width': '7%', height: '' }}>
													<div className="text-end">
														{item.short_name}
													</div>
												</th>
											))}
											<th style={{ 'width': '45%' }} className="text-nowrap text-end">
												{t('total')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data?.products?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.name}</td>
												<td className="text-center">{item.unit_name}</td>
												{item?.poses?.length &&
													<Fragment>
														{item.poses.map((item2, index2) => (
															<Fragment key={index2}>
																<td className="text-nowrap text-end hover"
																	onClick={() => toggleModalCheques(true, item2)}>
																	{formatMoney(item2.sale, 2)}
																</td>
															</Fragment>
														))}
													</Fragment>
												}
												<td className="text-nowrap text-end">
													<b>{formatMoney(item.total_sale, 2)}</b>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* MODAL CHEQUES */}
			<Modal show={modalCheques} animation={false} centered size="xl"
				dialogClassName="update-modal-width"
				onHide={() => toggleModalCheques(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('cheques')} [{formatMoney(cheques?.length)}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive" style={{ 'height': '500px', 'overflow': 'auto' }}>
						<table className="table table-bordered mb-0">
							<thead>
								<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
								<th style={{ 'width': '20%' }}>{t('pos')}</th>
								<th style={{ 'width': '10%' }}>{t('cashier')}</th>
								<th style={{ 'width': '10%' }}>{t('waiter')}</th>
								<th style={{ 'width': '10%' }} className=" text-center">
									{t('cheque')} №
								</th>
								<th style={{ 'width': '10%' }}>{t('hall')}</th>
								<th style={{ 'width': '10%' }}>{t('table')}</th>
								<th style={{ 'width': '10%' }} className="text-center">{t('open_date')}</th>
								<th style={{ 'width': '10%' }} className="text-center">{t('close_date')}</th>
								<th style={{ 'width': '10%' }} className="text-end">{t('total')}</th>
							</thead>
							<tbody>
								{cheques?.map((item, index) => (
									<tr className={`cursor ${item.showSidebar ? 'table-tr-active' : ''}`} key={index}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{index + 1}
											</CustomTooltip>
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.pos_name}
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.cashier_first_name}
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.waiter_first_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{item.order_type === 1 &&
												<CustomTooltip text={t('hall')}>
													<span className="bg-primary bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												</CustomTooltip>
											}
											{item.order_type === 2 &&
												<CustomTooltip text={t('takeaway')}>
													<span className="bg-success bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												</CustomTooltip>
											}
											{item.order_type === 3 &&
												<CustomTooltip text={t('delivery')}>
													<span className="bg-warning bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												</CustomTooltip>
											}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)}>
											{item.hall_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)}>
											{item.table_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{formatDate(item.cheque_opened_at, 'dd.MM.yy HH:mm')}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{formatDate(item.cheque_closed_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className={`text-end text-nowrap ${item.client_debtor_id ? 'bg-danger text-white' : ''}`}
											onClick={() => toggleRightbar(true, item, index)}>
											{formatMoney(item.total_amount_service)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CHEQUES */}

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>

			<div id="print" className="d-none">
				<div className="w80mm" ref={printRef}>
					<table className="table table-bordered mb-0">
						<thead>
							<tr>
								<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
								<th style={{ 'width': '50%' }}>{t('name2')}</th>
								<th style={{ 'width': '5%' }} className="text-center">
									{t('unit')}
								</th>
								{data?.poses?.map((item, index) => (
									<th key={index} style={{ 'width': '7%', height: '' }}>
										<div className="text-end">
											{item.short_name}
										</div>
									</th>
								))}
								<th style={{ 'width': '45%' }} className="text-nowrap text-end">
									{t('total')}
								</th>
							</tr>
						</thead>
						<tbody>
							{data?.products?.map((item, index) => (
								<tr key={index}>
									<td className="text-center">{index + 1}</td>
									<td>{item.name}</td>
									<td className="text-center">{item.unit_name}</td>
									{item?.poses?.length &&
										<Fragment>
											{item.poses.map((item2, index2) => (
												<Fragment key={index2}>
													<td className="text-nowrap text-end hover">
														{formatMoney(item2.sale, 2)}
													</td>
												</Fragment>
											))}
										</Fragment>
									}
									<td className="text-nowrap text-end">
										<b>{formatMoney(item.total_sale, 2)}</b>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}

export default Index