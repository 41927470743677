import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars

// Import menuDropdown
import LanguageDropdown from "../../LanguageDropdown";
import { CHANGE_ACCOUNT } from 'store/account/action'
import { clearTemporaryStorage, formatMoney } from 'helpers/helpers'

import { TOGGLE_LEFT_MENU } from "store/layout/actions";
import { httpOk, GET, PUT } from "helpers/api";

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const socketRef = useRef(null)
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	const layoutSelector = useSelector(state => state.layout)
	const reduxAccount = useSelector(state => state.account)

	const [pos, setPos] = useState(0)
	const [poses, setPoses] = useState([])
	const [modalLogout, setModalLogout] = useState(false)
	const [modalChangePos, setModalChangePos] = useState(false)
	const [showUserDropdown, setShowUserDropdown] = useState(false)

	function tToggle() {
		if (layoutSelector.showleftMenu) {
			dispatch(TOGGLE_LEFT_MENU(false));
		} else {
			dispatch(TOGGLE_LEFT_MENU(true));
		}
	}

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	async function toggleModalChangePos(bool) {
		setModalChangePos(bool)
	}

	async function changePos(item) {
		const response = await PUT('/user-default-pos', { 'pos_id': item.id }, { 'loader': true })
		if (httpOk(response)) {
			var reduxAccountCopy = JSON.parse(JSON.stringify(reduxAccount))
			reduxAccountCopy.pos_id = response.data.pos_id
			reduxAccountCopy.wms_id = response.data.wms_id

			dispatch(CHANGE_ACCOUNT(reduxAccountCopy))
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}

	async function logout() {
		dispatch({ 'type': 'USER_LOGGED_OUT', payload: null })
		clearTemporaryStorage()
		navigate('/auth/login')
	}

	async function getBalance() {
		const response = await GET('/pos-balance')
		if (httpOk(response)) setPos(response.data)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function connectSocket() {
		var wsHost;
		var authEndpoint;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			authEndpoint = process.env.REACT_APP_PROD_BROADCAST_AUTH
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_DEV_WSHOST
			authEndpoint = process.env.REACT_APP_DEV_BROADCAST_AUTH
			isProd = false
		}

		try {
			socketRef.current = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						'Authorization': `Bearer ${reduxAccount.token}`
					}
				},
			});
		} catch (error) {
			console.log(error)
		}

		socketRef.current.join(`general`)
	}

	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowUserDropdown(false)
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	useEffect(() => {
		if (localStorage.getItem('login')) {
			getBalance()
			if (!poses.length && reduxAccount.role === 'ROLE_OWNER') getPoses()
		}

		return () => {
			if (socketRef.current) {
				socketRef?.current?.leave(`general`)
			}
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<header id="page-topbar" className={layoutSelector.showleftMenu ? 'sidebar-active-left' : 'sidebar-left'}>
				<div className="navbar-header">
					<div className="d-flex">
						<button className="d-none" onClick={connectSocket}>connect</button>
						<button className="d-none" onClick={() => { socketRef.current.disconnect() }}>
							disconnect
						</button>
						<button type="button" id="vertical-menu-btn"
							className="btn btn-sm px-3 header-item waves-effect vertical-menu-btn"
							onClick={() => tToggle()}>
							<i className="uil-bars fz-20" />
						</button>
					</div>

					<div className="d-flex">
						<LanguageDropdown />

						<Dropdown className="d-lg-inline-block">
							{!!(poses.length > 1) &&
								<button type="button" className="btn header-item noti-icon waves-effect position-relative"
									onClick={toggleModalChangePos}>
									<i className="uil-shop"></i>
									<span style={{
										'position': 'absolute',
										'fontSize': '12px',
										'right': '4px',
										'top': '7px',
									}}>
										{poses.length}
									</span>
								</button>
							}
							<button type="button" className="btn header-item noti-icon waves-effect" data-toggle="fullscreen"
								onClick={toggleFullscreen}>
								<i className="uil-minus-path"></i>
							</button>
						</Dropdown>
						<div className="d-lg-inline-block ms-1 dropdown" >
							<button type="button" className="btn header-item noti-icon waves-effect" ref={wrapperRef}>
								<div className={`profile-dropdown ${pos?.balance < 0 && 'bounce-effect'}`}>
									<div className={`profile-dropdown-toggle ${(pos?.balance > 0 ? 'success' : 'danger')}`}
										onClick={() => setShowUserDropdown(!showUserDropdown)}>
										<i className="uil uil-user" color="#fff"></i>
									</div>
									{showUserDropdown &&
										<div className="profile-dropdown-menu">
											<div className="profile-dropdown-item">
												<div>{t('name')}:</div>
												<div>{reduxAccount.first_name}</div>
											</div>
											<div className="profile-dropdown-item">
												<div>{t('login')}:</div>
												<div>{reduxAccount.login}</div>
											</div>
											<div className="profile-dropdown-item">
												<div>ID:</div>
												<div>{reduxAccount.pos_id}</div>
											</div>
											<div className="profile-dropdown-item">
												<div>{t('balance')}:</div>
												<b className={`${pos?.balance < 0 && 'text-danger'}`}>
													{formatMoney(pos?.balance)}
												</b>
											</div>
											{reduxAccount.role === 'ROLE_OWNER' &&
												<div className="profile-dropdown-item second"
													onClick={() => {
														navigate('/settings')
														setShowUserDropdown(false)
													}}>
													{t('settings')}
													<i className="uil-cog"></i>
												</div>
											}
											<div className="profile-dropdown-item second d-none"
												onClick={() => {
													navigate('/payment')
													setShowUserDropdown(false)
												}}>
												{t('payment')}
												<i className="uil-transaction"></i>
											</div>
											<div className="profile-dropdown-item second"
												onClick={() => setModalLogout(true)}>
												<div>
													{t('logout')}
												</div>
												<i className="uil-signin"></i>
											</div>
										</div>
									}
								</div>
							</button>
						</div>
					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('logout')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('are_you_sure_you_want_logout')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalLogout(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => logout()}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>

			<Modal show={modalChangePos} animation={false}
				centered dialogClassName="update-modal-width"
				onHide={() => setModalChangePos(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('poses')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ 'height': '400px', 'overflow': 'auto' }}>
						{poses.map((item, index) => (
							<div className="d-flex justify-content-between mb-2" key={index}>
								<p className="fw-600 vertical-center">{item.name}</p>
								<div className="table-action-button table-action-success-button"
									onClick={() => changePos(item)}>
									<i className="uil uil-check"></i>
								</div>
							</div>
						))}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Header
